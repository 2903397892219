// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.

import axios, { AxiosRequestConfig, Method, AxiosError } from 'axios';
import { sleep } from '~/extensions/packages/sleep/sleep';
import { clientConfig } from '~/client/clientConfig';

const streamSaverPromise = typeof document !== 'undefined'
  // Importing the streamSaver library uses window which crashes in server-side rendering contexts
  ? import('streamsaver')
  : undefined;


//#region ApiCalls


//#region CaseConverters

export function formatRequest(input: any)
{
  const output : any = {};
  let key;
  let updatedKey;
  let value;
  if (input instanceof Array)
  {
    return input.map(function(value)
    {
      if (typeof value === 'object')
      {
        value = formatRequest(value);
      }
      return value;
    });
  }
  else
  {
    for (key in input)
    {
      if (input.hasOwnProperty(key))
      {
        value = input[key];
        if (value instanceof Array || (value !== null && typeof value !== 'undefined' && value.constructor === Object))
        {
          value = formatRequest(value);
        }

        updatedKey = key.includes('-')
          // This is likely not a property but a custom dictionary key (workaround for WM-1555)
          ? key
          : camelToPascal(key);
        output[updatedKey] = value;
      }
    }
  }
  return output;
}

const camelToPascal = (key: string) => (key.charAt(0).toUpperCase() + key.slice(1) || key).toString();
const pascalToCamel = (key: string) => (key.charAt(0).toLowerCase() + key.slice(1) || key).toString();

const formatKey = (key: string) => key.includes('-')
  // This is likely not a property but a custom dictionary key (workaround for WM-1555)
  ? key
  : pascalToCamel(key);

type ValueTransformer = (value: any) => any;

/**
 * It is our convention to use undefined throughout the codebase to reduce
 * confusion and ambiguity between undefined and null.
 *
 * Therefore, we map nulls from the API to undefined for use in Typescript.
 */
const transformNullToUndefined: ValueTransformer = (value) =>
    value === null ? undefined : value;

/**
 * Transformations applied to all json values before passing to the consumer
 */
const valueTransformations: ValueTransformer[]  = [
  transformNullToUndefined
];

const formatValue = <T>(value: T) =>
    // Apply all transformations
    valueTransformations.reduce((value, transform) => transform(value), value);

export function formatResponse(input: any): any
{
  const output: any = {};
  if (input instanceof Array)
  {
    return input.map((value) =>
        typeof value === 'object' && value !== null
            // It's an object or array, recurse
            ? formatResponse(value)
            : formatValue(value));
  }
  else
  {
    for (const key in input)
    {
      if (input.hasOwnProperty(key))
      {
        const value = input[key];

        // Format the key
        const formattedKey = formatKey(key);

        // Format the value
        const formattedValue = typeof value === 'object' && value !== null
          // It's an object or array, recurse
          ? formatResponse(value)
          : formatValue(value);

        // Build output object
        output[formattedKey] = formattedValue;
      }
    }
  }
  return output;
}

//#endregion CaseConverters


const prefix = () => clientConfig.origin + "/api"

async function callApi<TRequest, TResponse>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<TResponse>
{
  try {
    const axiosRequest = createAxiosRequest(method, path, formatRequest(request), false);
    const response = await axios(axiosRequest);
    return formatResponse(response.data);
  } catch(error) {
    const err = error as AxiosError;
    const csrfToken = err.response?.headers['X-XSRF-TOKEN'.toLowerCase()];
    if (typeof csrfToken !== 'undefined' && attemptCount < 6)
    {
      localStorage.setItem('XSRF-TOKEN', csrfToken);

      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);

      return callApi(method, path, request, attemptCount + 1);
    }
    throw error;
  }
}

async function callStreamApi<TRequest>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<void>
{
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const fetchResponse = await fetch(prefix() + path, {
    method: method,
    headers: {
      ...((csrfToken !== null) && { 'X-XSRF-TOKEN': csrfToken }),
      'Content-Type': 'application/json',
    },
    // Fetch requires this to be a string
    body: JSON.stringify(formatRequest(request)),
  });

  if (!fetchResponse.ok) {
    const csrfToken = fetchResponse.headers.get('X-XSRF-TOKEN'.toLowerCase());
    if (typeof csrfToken !== 'undefined' && attemptCount < 6) {
      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);
      return callStreamApi(method, path, request, attemptCount + 1);
    }
  }

  // Download stream
  const readableStream = fetchResponse.body;
  // Regex to get the filename from the Content-Disposition header
  const fileNameRegex = `(?<=filename=").*?(?=")`;
  const fileName = fetchResponse.headers.get('Content-Disposition')?.match(fileNameRegex)![0];
  const decodedFilename = decodeURIComponent(fileName!);
  if (window.WritableStream && readableStream?.pipeTo) {
    // We can assert StreamSaver exists now as this is a client-side context
    const streamSaver = (await streamSaverPromise!).default;
    // Override mitm.html location from jimmywarting.github.io to self-hosted file in php
    streamSaver.mitm = '/stream-saver/mitm.html';
    const fileStream = streamSaver.createWriteStream(decodedFilename);
    return readableStream.pipeTo(fileStream);
  }
}

function createAxiosRequest<TRequest>(callMethod: Method, path: string, request: TRequest, isStreamRequest: boolean): AxiosRequestConfig
{
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const requestConfig: AxiosRequestConfig =
  {
    method: callMethod,
    url: prefix() + path,
    data: request,
    headers: {
      ...((csrfToken !== null) && {'X-XSRF-TOKEN': csrfToken})
    }
  };
  if (isStreamRequest)
  {
    requestConfig.responseType = 'stream';
    requestConfig.timeout = 99000; // milliseconds
  }
  return requestConfig;
}

//#endregion ApiCalls


//#region Imports

import { EmptyRequest as BeastClientCommonControllerControllerEmptyRequest, EmptyResponse as BeastClientCommonControllerControllerEmptyResponse } from "@BeastClient/Common/Controller/Controller.gen"
import { Response as BeastClientBeastActivityModuleSearchControllerActivityAlertsControllerNestedResponse } from "@BeastClient/Beast/Activity/Module/Search/Controller/ActivityAlertsControllerNested.gen"
import { Request as BeastClientBeastActivityModuleSearchControllerActivitySearchControllerNestedRequest, Response as BeastClientBeastActivityModuleSearchControllerActivitySearchControllerNestedResponse } from "@BeastClient/Beast/Activity/Module/Search/Controller/ActivitySearchControllerNested.gen"
import { Request as BeastClientBeastActivityModuleSearchControllerActivitySearchAdminControllerNestedRequest, Response as BeastClientBeastActivityModuleSearchControllerActivitySearchAdminControllerNestedResponse } from "@BeastClient/Beast/Activity/Module/Search/Controller/ActivitySearchAdminControllerNested.gen"
import { Request as BeastClientBeastActivityModuleUserInteractionControllerActivityUserInteractControllerNestedRequest } from "@BeastClient/Beast/Activity/Module/User/Interaction/Controller/ActivityUserInteractControllerNested.gen"
import { EmptyRequest as BeastClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest } from "@BeastClient/ApiServer/Packages/AspNetCore/Controller/Request.gen"
import { Response as BeastClientBeastNotificationPackagesModulePackagesNotificationControllerNotificationInAppListControllerNestedResponse } from "@BeastClient/Beast/Notification/Packages/Module/Packages/Notification/Controller/NotificationInAppListControllerNested.gen"
import { Request as BeastClientBeastNotificationPackagesModulePackagesNotificationControllerNotificationInAppMarkAsReadControllerNestedRequest } from "@BeastClient/Beast/Notification/Packages/Module/Packages/Notification/Controller/NotificationInAppMarkAsReadControllerNested.gen"
import { Response as BeastClientBeastAssessmentControllerAssessmentAvailabilitiesGetNestedResponse } from "@BeastClient/Beast/Assessment/Controller/AssessmentAvailabilitiesGetNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentCriterionControllerAssessmentCriterionCreateNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentCriterion/Controller/AssessmentCriterionCreateNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCommentUpdateNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationCommentUpdateNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCreateNestedRequest, Response as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCreateNestedResponse } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationCreateNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCriteriaSelectNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationCriteriaSelectNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationDeleteNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationDeleteNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationDetailsUpdateNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationDetailsUpdateNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationGetNestedRequest, Response as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationGetNestedResponse } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationGetNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationListGetNestedRequest, Response as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationListGetNestedResponse } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationListGetNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationMarkCompleteNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationMarkCompleteNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationMarkIncompleteNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationMarkIncompleteNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationPackagesAssessmentEvaluationQuestionPackagesAssessmentEvaluationQuestionCommentControllerAssessmentEvaluationQuestionCommentInternalUpsertNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Packages/AssessmentEvaluationQuestion/Packages/AssessmentEvaluationQuestionComment/Controller/AssessmentEvaluationQuestionCommentInternalUpsertNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationPackagesAssessmentEvaluationQuestionPackagesAssessmentEvaluationQuestionCommentControllerAssessmentEvaluationQuestionCommentPublicUpsertNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Packages/AssessmentEvaluationQuestion/Packages/AssessmentEvaluationQuestionComment/Controller/AssessmentEvaluationQuestionCommentPublicUpsertNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationQuestionInitiativeLinkNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationQuestionInitiativeLinkNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationQuestionTicketCreateNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationQuestionTicketCreateNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateCreateNestedRequest, Response as BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateCreateNestedResponse } from "@BeastClient/Beast/Assessment/Packages/AssessmentTemplate/Controller/AssessmentTemplateCreateNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateDeleteNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentTemplate/Controller/AssessmentTemplateDeleteNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateGetNestedRequest, Response as BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateGetNestedResponse } from "@BeastClient/Beast/Assessment/Packages/AssessmentTemplate/Controller/AssessmentTemplateGetNested.gen"
import { Response as BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateListNestedResponse } from "@BeastClient/Beast/Assessment/Packages/AssessmentTemplate/Controller/AssessmentTemplateListNested.gen"
import { Request as BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateUpdateNestedRequest } from "@BeastClient/Beast/Assessment/Packages/AssessmentTemplate/Controller/AssessmentTemplateUpdateNested.gen"
import { Request as BeastClientBeastBudgetForecastControllerBudgetForecastGetNestedRequest, Response as BeastClientBeastBudgetForecastControllerBudgetForecastGetNestedResponse } from "@BeastClient/Beast/BudgetForecast/Controller/BudgetForecastGetNested.gen"
import { Response as BeastClientBeastFeaturePackagesAccessControllerFeatureAvailabilitiesGetNestedResponse } from "@BeastClient/Beast/Feature/Packages/Access/Controller/FeatureAvailabilitiesGetNested.gen"
import { Request as BeastClientBeastInitiativeControllerInitiativeAssessmentEvaluationQuestionListNestedRequest, Response as BeastClientBeastInitiativeControllerInitiativeAssessmentEvaluationQuestionListNestedResponse } from "@BeastClient/Beast/Initiative/Controller/InitiativeAssessmentEvaluationQuestionListNested.gen"
import { Request as BeastClientBeastInitiativePackagesInitiativeLinkControllerInitiativeLinkAssessmentEvaluationQuestionUnlinkNestedRequest } from "@BeastClient/Beast/Initiative/Packages/InitiativeLink/Controller/InitiativeLinkAssessmentEvaluationQuestionUnlinkNested.gen"
import { Request as BeastClientBeastIntegrationControllerDocumentationSupportedIntegrationsGetControllerNestedRequest, Response as BeastClientBeastIntegrationControllerDocumentationSupportedIntegrationsGetControllerNestedResponse } from "@BeastClient/Beast/Integration/Controller/Documentation/SupportedIntegrationsGetControllerNested.gen"
import { Response as BeastClientBeastIntegrationControllerDocumentationSupportedIntegrationsListControllerNestedResponse } from "@BeastClient/Beast/Integration/Controller/Documentation/SupportedIntegrationsListControllerNested.gen"

//#endregion Imports


//#region Operations

export function activityAlerts(request: BeastClientCommonControllerControllerEmptyRequest): Promise<BeastClientBeastActivityModuleSearchControllerActivityAlertsControllerNestedResponse>
{
  return callApi<BeastClientCommonControllerControllerEmptyRequest, BeastClientBeastActivityModuleSearchControllerActivityAlertsControllerNestedResponse>("POST", "/Activity/Activity/Alerts", request);
}

export function activitySearch(request: BeastClientBeastActivityModuleSearchControllerActivitySearchControllerNestedRequest): Promise<BeastClientBeastActivityModuleSearchControllerActivitySearchControllerNestedResponse>
{
  return callApi<BeastClientBeastActivityModuleSearchControllerActivitySearchControllerNestedRequest, BeastClientBeastActivityModuleSearchControllerActivitySearchControllerNestedResponse>("POST", "/Activity/Activity/Search", request);
}

export function activityUserInteract(request: BeastClientBeastActivityModuleUserInteractionControllerActivityUserInteractControllerNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastActivityModuleUserInteractionControllerActivityUserInteractControllerNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Activity/Activity/User/Interact", request);
}

export function notificationInAppMarkAsRead(request: BeastClientBeastNotificationPackagesModulePackagesNotificationControllerNotificationInAppMarkAsReadControllerNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastNotificationPackagesModulePackagesNotificationControllerNotificationInAppMarkAsReadControllerNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Activity/Notification/InAppMarkAsRead", request);
}

export function assessmentAvailabilitiesGet(request: BeastClientCommonControllerControllerEmptyRequest): Promise<BeastClientBeastAssessmentControllerAssessmentAvailabilitiesGetNestedResponse>
{
  return callApi<BeastClientCommonControllerControllerEmptyRequest, BeastClientBeastAssessmentControllerAssessmentAvailabilitiesGetNestedResponse>("POST", "/Assessment/Assessment/Availabilities/Get", request);
}

export function assessmentCriterionCreate(request: BeastClientBeastAssessmentPackagesAssessmentCriterionControllerAssessmentCriterionCreateNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentCriterionControllerAssessmentCriterionCreateNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Criterion/Create", request);
}

export function assessmentEvaluationCommentUpdate(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCommentUpdateNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCommentUpdateNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Evaluation/Comment/Update", request);
}

export function assessmentEvaluationCreate(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCreateNestedRequest): Promise<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCreateNestedResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCreateNestedRequest, BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCreateNestedResponse>("POST", "/Assessment/Assessment/Evaluation/Create", request);
}

export function assessmentEvaluationCriteriaSelect(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCriteriaSelectNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCriteriaSelectNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Evaluation/Criteria/Select", request);
}

export function assessmentEvaluationDelete(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationDeleteNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationDeleteNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Evaluation/Delete", request);
}

export function assessmentEvaluationDetailsUpdate(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationDetailsUpdateNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationDetailsUpdateNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Evaluation/Details/Update", request);
}

export function assessmentEvaluationGet(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationGetNestedRequest): Promise<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationGetNestedResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationGetNestedRequest, BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationGetNestedResponse>("POST", "/Assessment/Assessment/Evaluation/Get", request);
}

export function assessmentEvaluationListGet(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationListGetNestedRequest): Promise<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationListGetNestedResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationListGetNestedRequest, BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationListGetNestedResponse>("POST", "/Assessment/Assessment/Evaluation/List/Get", request);
}

export function assessmentEvaluationMarkComplete(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationMarkCompleteNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationMarkCompleteNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Evaluation/Mark/Complete", request);
}

export function assessmentEvaluationMarkIncomplete(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationMarkIncompleteNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationMarkIncompleteNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Evaluation/Mark/Incomplete", request);
}

export function assessmentEvaluationQuestionCommentInternalUpsert(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationPackagesAssessmentEvaluationQuestionPackagesAssessmentEvaluationQuestionCommentControllerAssessmentEvaluationQuestionCommentInternalUpsertNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationPackagesAssessmentEvaluationQuestionPackagesAssessmentEvaluationQuestionCommentControllerAssessmentEvaluationQuestionCommentInternalUpsertNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Evaluation/Question/Comment/Internal/Upsert", request);
}

export function assessmentEvaluationQuestionCommentPublicUpsert(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationPackagesAssessmentEvaluationQuestionPackagesAssessmentEvaluationQuestionCommentControllerAssessmentEvaluationQuestionCommentPublicUpsertNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationPackagesAssessmentEvaluationQuestionPackagesAssessmentEvaluationQuestionCommentControllerAssessmentEvaluationQuestionCommentPublicUpsertNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Evaluation/Question/Comment/Public/Upsert", request);
}

export function assessmentEvaluationQuestionInitiativeLink(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationQuestionInitiativeLinkNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationQuestionInitiativeLinkNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Evaluation/Question/Initiative/Link", request);
}

export function assessmentEvaluationQuestionTicketCreate(request: BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationQuestionTicketCreateNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationQuestionTicketCreateNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Evaluation/Question/Ticket/Create", request);
}

export function assessmentTemplateCreate(request: BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateCreateNestedRequest): Promise<BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateCreateNestedResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateCreateNestedRequest, BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateCreateNestedResponse>("POST", "/Assessment/Assessment/Template/Create", request);
}

export function assessmentTemplateDelete(request: BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateDeleteNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateDeleteNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Template/Delete", request);
}

export function assessmentTemplateGet(request: BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateGetNestedRequest): Promise<BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateGetNestedResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateGetNestedRequest, BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateGetNestedResponse>("POST", "/Assessment/Assessment/Template/Get", request);
}

export function assessmentTemplateList(request: BeastClientCommonControllerControllerEmptyRequest): Promise<BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateListNestedResponse>
{
  return callApi<BeastClientCommonControllerControllerEmptyRequest, BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateListNestedResponse>("POST", "/Assessment/Assessment/Template/List", request);
}

export function assessmentTemplateUpdate(request: BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateUpdateNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateUpdateNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Assessment/Template/Update", request);
}

export function budgetForecastGet(request: BeastClientBeastBudgetForecastControllerBudgetForecastGetNestedRequest): Promise<BeastClientBeastBudgetForecastControllerBudgetForecastGetNestedResponse>
{
  return callApi<BeastClientBeastBudgetForecastControllerBudgetForecastGetNestedRequest, BeastClientBeastBudgetForecastControllerBudgetForecastGetNestedResponse>("POST", "/BudgetForecast/BudgetForecast/Get", request);
}

export function initiativeAssessmentEvaluationQuestionList(request: BeastClientBeastInitiativeControllerInitiativeAssessmentEvaluationQuestionListNestedRequest): Promise<BeastClientBeastInitiativeControllerInitiativeAssessmentEvaluationQuestionListNestedResponse>
{
  return callApi<BeastClientBeastInitiativeControllerInitiativeAssessmentEvaluationQuestionListNestedRequest, BeastClientBeastInitiativeControllerInitiativeAssessmentEvaluationQuestionListNestedResponse>("POST", "/Initiative/Initiative/AssessmentEvaluationQuestion/List", request);
}

export function initiativeLinkAssessmentEvaluationQuestionUnlink(request: BeastClientBeastInitiativePackagesInitiativeLinkControllerInitiativeLinkAssessmentEvaluationQuestionUnlinkNestedRequest): Promise<BeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<BeastClientBeastInitiativePackagesInitiativeLinkControllerInitiativeLinkAssessmentEvaluationQuestionUnlinkNestedRequest, BeastClientCommonControllerControllerEmptyResponse>("POST", "/Initiative/InitiativeLink/AssessmentEvaluationQuestion/Unlink", request);
}


//#endregion Operations


