// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.

import axios, { AxiosRequestConfig, Method, AxiosError } from 'axios';
import { sleep } from '~/extensions/packages/sleep/sleep';
import { clientConfig } from '~/client/clientConfig';

const streamSaverPromise = typeof document !== 'undefined'
  // Importing the streamSaver library uses window which crashes in server-side rendering contexts
  ? import('streamsaver')
  : undefined;


//#region ApiCalls


//#region CaseConverters

export function formatRequest(input: any)
{
  const output : any = {};
  let key;
  let updatedKey;
  let value;
  if (input instanceof Array)
  {
    return input.map(function(value)
    {
      if (typeof value === 'object')
      {
        value = formatRequest(value);
      }
      return value;
    });
  }
  else
  {
    for (key in input)
    {
      if (input.hasOwnProperty(key))
      {
        value = input[key];
        if (value instanceof Array || (value !== null && typeof value !== 'undefined' && value.constructor === Object))
        {
          value = formatRequest(value);
        }

        updatedKey = key.includes('-')
          // This is likely not a property but a custom dictionary key (workaround for WM-1555)
          ? key
          : camelToPascal(key);
        output[updatedKey] = value;
      }
    }
  }
  return output;
}

const camelToPascal = (key: string) => (key.charAt(0).toUpperCase() + key.slice(1) || key).toString();
const pascalToCamel = (key: string) => (key.charAt(0).toLowerCase() + key.slice(1) || key).toString();

const formatKey = (key: string) => key.includes('-')
  // This is likely not a property but a custom dictionary key (workaround for WM-1555)
  ? key
  : pascalToCamel(key);

type ValueTransformer = (value: any) => any;

/**
 * It is our convention to use undefined throughout the codebase to reduce
 * confusion and ambiguity between undefined and null.
 *
 * Therefore, we map nulls from the API to undefined for use in Typescript.
 */
const transformNullToUndefined: ValueTransformer = (value) =>
    value === null ? undefined : value;

/**
 * Transformations applied to all json values before passing to the consumer
 */
const valueTransformations: ValueTransformer[]  = [
  transformNullToUndefined
];

const formatValue = <T>(value: T) =>
    // Apply all transformations
    valueTransformations.reduce((value, transform) => transform(value), value);

export function formatResponse(input: any): any
{
  const output: any = {};
  if (input instanceof Array)
  {
    return input.map((value) =>
        typeof value === 'object' && value !== null
            // It's an object or array, recurse
            ? formatResponse(value)
            : formatValue(value));
  }
  else
  {
    for (const key in input)
    {
      if (input.hasOwnProperty(key))
      {
        const value = input[key];

        // Format the key
        const formattedKey = formatKey(key);

        // Format the value
        const formattedValue = typeof value === 'object' && value !== null
          // It's an object or array, recurse
          ? formatResponse(value)
          : formatValue(value);

        // Build output object
        output[formattedKey] = formattedValue;
      }
    }
  }
  return output;
}

//#endregion CaseConverters


const prefix = () => clientConfig.origin + "/api/AssetManagement"

async function callApi<TRequest, TResponse>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<TResponse>
{
  try {
    const axiosRequest = createAxiosRequest(method, path, formatRequest(request), false);
    const response = await axios(axiosRequest);
    return formatResponse(response.data);
  } catch(error) {
    const err = error as AxiosError;
    const csrfToken = err.response?.headers['X-XSRF-TOKEN'.toLowerCase()];
    if (typeof csrfToken !== 'undefined' && attemptCount < 6)
    {
      localStorage.setItem('XSRF-TOKEN', csrfToken);

      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);

      return callApi(method, path, request, attemptCount + 1);
    }
    throw error;
  }
}

async function callStreamApi<TRequest>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<void>
{
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const fetchResponse = await fetch(prefix() + path, {
    method: method,
    headers: {
      ...((csrfToken !== null) && { 'X-XSRF-TOKEN': csrfToken }),
      'Content-Type': 'application/json',
    },
    // Fetch requires this to be a string
    body: JSON.stringify(formatRequest(request)),
  });

  if (!fetchResponse.ok) {
    const csrfToken = fetchResponse.headers.get('X-XSRF-TOKEN'.toLowerCase());
    if (typeof csrfToken !== 'undefined' && attemptCount < 6) {
      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);
      return callStreamApi(method, path, request, attemptCount + 1);
    }
  }

  // Download stream
  const readableStream = fetchResponse.body;
  // Regex to get the filename from the Content-Disposition header
  const fileNameRegex = `(?<=filename=").*?(?=")`;
  const fileName = fetchResponse.headers.get('Content-Disposition')?.match(fileNameRegex)![0];
  const decodedFilename = decodeURIComponent(fileName!);
  if (window.WritableStream && readableStream?.pipeTo) {
    // We can assert StreamSaver exists now as this is a client-side context
    const streamSaver = (await streamSaverPromise!).default;
    // Override mitm.html location from jimmywarting.github.io to self-hosted file in php
    streamSaver.mitm = '/stream-saver/mitm.html';
    const fileStream = streamSaver.createWriteStream(decodedFilename);
    return readableStream.pipeTo(fileStream);
  }
}

function createAxiosRequest<TRequest>(callMethod: Method, path: string, request: TRequest, isStreamRequest: boolean): AxiosRequestConfig
{
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const requestConfig: AxiosRequestConfig =
  {
    method: callMethod,
    url: prefix() + path,
    data: request,
    headers: {
      ...((csrfToken !== null) && {'X-XSRF-TOKEN': csrfToken})
    }
  };
  if (isStreamRequest)
  {
    requestConfig.responseType = 'stream';
    requestConfig.timeout = 99000; // milliseconds
  }
  return requestConfig;
}

//#endregion ApiCalls


//#region Imports

import { Request as AssetManagementClientAssetManagementPackagesAccountExecutivePackagesListControllerAccountExecutiveListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAccountExecutivePackagesListControllerAccountExecutiveListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/AccountExecutive/Packages/List/Controller/AccountExecutiveListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Console/Controller/AssetConsoleControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleIdsControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleIdsControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Console/Controller/AssetConsoleIdsControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleSpreadsheetControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Asset/Console/Controller/AssetConsoleSpreadsheetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDetailsControllerHardwareDetailsGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDetailsControllerHardwareDetailsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Details/Controller/HardwareDetailsGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerScopedHardwareTypeStatsGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerScopedHardwareTypeStatsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Stats/Controller/ScopedHardwareTypeStatsGetControllerNested.gen"
import { EmptyRequest as AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest } from "@AssetManagementClient/ApiServer/Packages/AspNetCore/Controller/Request.gen"
import { WidgetResponse as AssetManagementClientDashboardSdkModelSimpleCountComponentNestedSimpleCountDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse } from "@AssetManagementClient/Dashboard/Sdk/Model/SimpleCountComponentNested/SimpleCountDto_/SimplePrerequisiteComponentNested/SimplePrerequisiteDto_.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareQueryFilterGetAvailableControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareQueryFilterGetAvailableControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Software/Packages/Query/Filter/Controller/SoftwareQueryFilterGetAvailableControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareSearchRowControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareSearchRowControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Software/Packages/Query/Filter/Controller/SoftwareSearchRowControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareSearchSpreadsheetControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Asset/Software/Packages/Query/Filter/Controller/SoftwareSearchSpreadsheetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportDeleteControllerNested.gen"
import { EmptyResponse as AssetManagementClientCommonControllerControllerEmptyResponse, EmptyRequest as AssetManagementClientCommonControllerControllerEmptyRequest } from "@AssetManagementClient/Common/Controller/Controller.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportEmailTemplateGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportEmailTemplateGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportEmailTemplateGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportEmailTemplateUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportEmailTemplateUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportPdfPreviewControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportPdfPreviewControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportPreviewSendControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportPreviewSendControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportPackagesRecipientControllerAutoEmailReportRecipientAddControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Packages/Recipient/Controller/AutoEmailReportRecipientAddControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportPackagesRecipientControllerAutoEmailReportRecipientRemoveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Packages/Recipient/Controller/AutoEmailReportRecipientRemoveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportScheduleUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportScheduleUpdateControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportTemplateAvailabilityListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportTemplateAvailabilityListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportViewUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportViewUpdateControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesStrategyPackagesBrandingControllerBrandingMspGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Branding/Controller/BrandingMspGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastAvailabilitiesGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/BudgetForecast/Controller/BudgetForecastAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastDetailPdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/BudgetForecast/Controller/BudgetForecastDetailPdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastPdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/BudgetForecast/Controller/BudgetForecastPdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractAgreementListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractControllerContractAgreementListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractAgreementListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractAvailabilitiesGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractControllerContractAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractControllerContractCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractControllerContractGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractControllerContractListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractListCsvControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractListCsvControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractListPdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractListPdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractListSpreadsheetControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractListSpreadsheetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractSyncControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractSyncControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractPackagesSyncControllerContractSyncGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractPackagesSyncControllerContractSyncGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Packages/Sync/Controller/ContractSyncGetControllerNested.gen"
import { EmptyRequest as AssetManagementClientBeastClientCommonControllerControllerEmptyRequest, EmptyResponse as AssetManagementClientBeastClientCommonControllerControllerEmptyResponse } from "@AssetManagementClient/BeastClient/Common/Controller/Controller.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractPackagesSyncControllerContractSyncToggleControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Packages/Sync/Controller/ContractSyncToggleControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Packages/Template/Controller/ContractTemplateAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Packages/Template/Controller/ContractTemplateCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Packages/Template/Controller/ContractTemplateGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareLifecycleReportPdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Device/Controller/HardwareLifecycleReportPdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareLifecycleReportSettingsGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareLifecycleReportSettingsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Device/Controller/HardwareLifecycleReportSettingsGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalDisposalAddressValidateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/DisposalAddressValidateControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsDisposalAddressValidateControllerNestedResponse_ApiError_Result } from "@AssetManagementClient/Primitives/Results/DisposalAddressValidateControllerNested/Response_/ApiError_.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalAssetTypeControllerDisposalAssetTypeAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalAssetType/Controller/DisposalAssetTypeAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesCartDisposalCartUpsertControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Cart/DisposalCartUpsertControllerNested.gen"
import { Ok as AssetManagementClientPrimitivesResultsOk } from "@AssetManagementClient/Primitives/Results.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesCheckoutPackagesDisposalCheckoutBulkControllerDisposalCheckoutBulkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Checkout/Packages/DisposalCheckoutBulk/Controller/DisposalCheckoutBulkControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsDisposalCheckoutBulkControllerNestedResponse_ApiError_Result } from "@AssetManagementClient/Primitives/Results/DisposalCheckoutBulkControllerNested/Response_/ApiError_.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesCheckoutControllerDisposalCheckoutDataGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Checkout/Controller/DisposalCheckoutDataGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesCheckoutPackagesDisposalCheckoutSelfControllerDisposalCheckoutSelfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Checkout/Packages/DisposalCheckoutSelf/Controller/DisposalCheckoutSelfControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsDisposalCheckoutSelfControllerNestedResponse_ApiError_Result } from "@AssetManagementClient/Primitives/Results/DisposalCheckoutSelfControllerNested/Response_/ApiError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesCouponDisposalCouponCodeValidateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Coupon/DisposalCouponCodeValidateControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsDisposalCouponCodeValidateControllerNestedResponse_ErrorPayload_Result } from "@AssetManagementClient/Primitives/Results/DisposalCouponCodeValidateControllerNested/Response_/ErrorPayload_.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderAssetOrganizationAssignControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderAssetOrganizationAssignControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderAssetOrganizationUnassignedListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderAssetOrganizationUnassignedListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderAssetOrganizationUnassignedListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderInfoGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderInfoGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderInfoGetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPackagePurchaseControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPackagePurchaseControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPackagesLabelingProgressControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPackagesLabelingProgressControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsProgressResponse_ProgressError_Result } from "@AssetManagementClient/Primitives/Results/ProgressResponse_/ProgressError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPdfsControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPdfsControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupBulkAvailabilityScheduleControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPickupBulkAvailabilityScheduleControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupCancelControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPickupCancelControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupImageListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupImageListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPickupImageListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupImageUploadControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPickupImageUploadControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsDisposalOrderPickupImageUploadControllerNestedResponse_DisposalOrderPickupImageUploadError_Result } from "@AssetManagementClient/Primitives/Results/DisposalOrderPickupImageUploadControllerNested/Response_/DisposalOrderPickupImageUploadError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupScheduleControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPickupScheduleControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderShippingLabelGetControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderShippingLabelGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderInternalSpreadsheetControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderInternalSpreadsheetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderAssetOrphanCountControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderOrderAssetOrphanCountControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderAssetOrphanListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderOrderAssetOrphanListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderOrderGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderOrderListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderNotesAddControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderOrderNotesAddControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderPickupImageListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderPickupImageListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderOrderPickupImageListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderSchedulingGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderSchedulingGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderSchedulingGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderSchedulingListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderSchedulingListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderSchedulingListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderSchedulingPickupScheduleControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderSchedulingPickupScheduleControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDmiControllerApplicationDmiScoreControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Dmi/Controller/ApplicationDmiScoreControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDmiControllerScopeDmiScoreControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDmiControllerScopeDmiScoreControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Dmi/Controller/ScopeDmiScoreControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDmiControllerDmiTrendGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDmiControllerDmiTrendGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Dmi/Controller/DmiTrendGetControllerNested.gen"
import { WidgetResponse as AssetManagementClientDashboardSdkModelDmiScoreComponentNestedDmiScoreDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse } from "@AssetManagementClient/Dashboard/Sdk/Model/DmiScoreComponentNested/DmiScoreDto_/SimplePrerequisiteComponentNested/SimplePrerequisiteDto_.gen"
import { Response as AssetManagementClientAssetManagementPackagesFeatureControllerFeatureAccessInfoGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Feature/Controller/FeatureAccessInfoGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesFeatureControllerFeatureAccessOrganizationUnlockControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Feature/Controller/FeatureAccessOrganizationUnlockControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesFeatureControllerFeatureAccessOrganizationUnlockClientFocusScorecardRoadmapControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Feature/Controller/FeatureAccessOrganizationUnlockClientFocusScorecardRoadmapControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesConsoleHardwareConsoleIdLookupControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesConsoleHardwareConsoleIdLookupControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Console/HardwareConsoleIdLookupControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareConsoleOrganizationPdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Device/Controller/HardwareConsoleOrganizationPdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerHardwareStatsCountGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerHardwareStatsCountGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Stats/Controller/HardwareStatsCountGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerHardwareCountSummaryGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerHardwareCountSummaryGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Stats/Controller/HardwareCountSummaryGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementReportAssetTypeSettingsAvailabilitiesGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementReportAssetTypeSettingsAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/HardwareReplacement/Packages/Settings/Get/Controller/HardwareReplacementReportAssetTypeSettingsAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementSettingsGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementSettingsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/HardwareReplacement/Packages/Settings/Get/Controller/HardwareReplacementSettingsGetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesHardwareSettingsControllerHardwareSettingsAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/HardwareSettings/Controller/HardwareSettingsAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeAssetAddBulkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeAssetAddBulkControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeAssetControllerInitiativeAssetMoveBulkControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeAssetControllerInitiativeAssetMoveBulkControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Packages/InitiativeAsset/Controller/InitiativeAssetMoveBulkControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeAvailabilitiesGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeDraftCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeDraftCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeDraftCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEditControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEditControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeEditControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEngagementActionUnlinkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeEngagementActionUnlinkControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEngagementActionLinkBulkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeEngagementActionLinkBulkControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeOverviewDashboardListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeOverviewDashboardListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeOverviewDashboardListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativePdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativePdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeScheduleUpdateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeScheduleUpdateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeScheduleUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeStatusUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeStatusUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeTemplateControllerInitiativeTemplateAvailabilityControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeTemplateControllerInitiativeTemplateAvailabilityControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Packages/InitiativeTemplate/Controller/InitiativeTemplateAvailabilityControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeTemplateCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeTemplateDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeTemplateUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInsightsControllerInsightCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Insights/Controller/InsightCreateControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesInsightsControllerInsightCreateFormMetadataControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Insights/Controller/InsightCreateFormMetadataControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInsightsControllerInsightCustomListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInsightsControllerInsightCustomListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Insights/Controller/InsightCustomListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInsightsControllerInsightDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Insights/Controller/InsightDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInsightsControllerInsightListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInsightsControllerInsightListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Insights/Controller/InsightListGetControllerNested.gen"
import { WidgetResponse as AssetManagementClientDashboardSdkModelAreaChartComponentNestedAreaChartDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse } from "@AssetManagementClient/Dashboard/Sdk/Model/AreaChartComponentNested/AreaChartDto_/SimplePrerequisiteComponentNested/SimplePrerequisiteDto_.gen"
import { WidgetResponse as AssetManagementClientDashboardSdkModelSimpleTableComponentNestedSimpleTableDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse } from "@AssetManagementClient/Dashboard/Sdk/Model/SimpleTableComponentNested/SimpleTableDto_/SimplePrerequisiteComponentNested/SimplePrerequisiteDto_.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationPackagesDeleteControllerIntegrationDeleteNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Integration/Packages/Delete/Controller/IntegrationDeleteNested.gen"
import { Result as AssetManagementClientPrimitivesResultsEmptyResponse_IntegrationDeleteError_Result } from "@AssetManagementClient/Primitives/Results/EmptyResponse_/IntegrationDeleteError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationSetupGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationSetupGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationSetupGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationSetupUpsertControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationSetupUpsertControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsIntegrationSetupUpsertControllerNestedResponse_IntegrationSetupError_Result } from "@AssetManagementClient/Primitives/Results/IntegrationSetupUpsertControllerNested/Response_/IntegrationSetupError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationPackagesSetupControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesIntegrationPackagesSetupControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Integration/Packages/Setup/Controller/IntegrationSyncNotificationSubscriptionStatusControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationPackagesNotificationControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesIntegrationPackagesNotificationControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Integration/Packages/Notification/Controller/IntegrationSyncNotificationSubscriptionStatusControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationPackagesNotificationControllerIntegrationSyncNotificationSubscriptionUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Integration/Packages/Notification/Controller/IntegrationSyncNotificationSubscriptionUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationProductGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationProductGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationProductGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationPageControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationPageControllerNested.gen"
import { Response as AssetManagementClientBeastClientBeastIntegrationControllerIntegrationPageIntegrationVendorListNestedResponse } from "@AssetManagementClient/BeastClient/Beast/Integration/Controller/IntegrationPage/IntegrationVendorListNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesManufacturerManufacturerListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Manufacturer/ManufacturerListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberAddControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationAccountTeamMember/OrganizationAccountTeamMemberAddControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberEditControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationAccountTeamMember/OrganizationAccountTeamMemberEditControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationAccountTeamMember/OrganizationAccountTeamMemberListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberRemoveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationAccountTeamMember/OrganizationAccountTeamMemberRemoveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberPackagesOrganizationAccountTeamMemberRoleControllerOrganizationAccountTeamMemberRoleAddControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationAccountTeamMember/Packages/OrganizationAccountTeamMemberRole/Controller/OrganizationAccountTeamMemberRoleAddControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberPackagesOrganizationAccountTeamMemberRoleControllerOrganizationAccountTeamMemberRoleDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationAccountTeamMember/Packages/OrganizationAccountTeamMemberRole/Controller/OrganizationAccountTeamMemberRoleDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesBasicInfoOrganizationBasicInfoGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesBasicInfoOrganizationBasicInfoGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/BasicInfo/OrganizationBasicInfoGetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesBasicInfoOrganizationBasicInfoListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/BasicInfo/OrganizationBasicInfoListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationContactOrganizationContactListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationContactOrganizationContactListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationContact/OrganizationContactListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionInitiativeLinkListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionInitiativeLinkListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionInitiativeLinkListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListDashboardGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListDashboardGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionListDashboardGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkCompletedControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkCompletedControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionMarkCompletedControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkIncompleteControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkIncompleteControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionMarkIncompleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionPinControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionPinControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionPinControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionTicketCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionTicketCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionTicketUnlinkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionTicketUnlinkControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUnpinControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUnpinControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionUnpinControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUpdateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUpdateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteArchiveControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteArchiveControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNoteArchiveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNoteCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteDashboardListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteDashboardListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNoteDashboardListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNoteListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNotePinControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNotePinControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNotePinControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnarchiveControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnarchiveControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNoteUnarchiveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnpinControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnpinControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNoteUnpinControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementActionUpdateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementActionUpdateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementActionUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactAddControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationKeyContact/OrganizationKeyContactAddControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationKeyContact/OrganizationKeyContactListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactRemoveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationKeyContact/OrganizationKeyContactRemoveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkControllerOrganizationLinkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Link/Controller/OrganizationLinkControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsOrganizationLinkControllerNestedResponse_OrganizationLinkError_Result } from "@AssetManagementClient/Primitives/Results/OrganizationLinkControllerNested/Response_/OrganizationLinkError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkPackagesListControllerOrganizationLinkListControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Link/Packages/List/Controller/OrganizationLinkListControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsOrganizationLinkListControllerNestedResponse_OrganizationLinkError_Result } from "@AssetManagementClient/Primitives/Results/OrganizationLinkListControllerNested/Response_/OrganizationLinkError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkPackagesProgressControllerOrganizationLinkProgressGetControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Link/Packages/Progress/Controller/OrganizationLinkProgressGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkPackagesSearchControllerOrganizationLinkAvailabilitySearchControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Link/Packages/Search/Controller/OrganizationLinkAvailabilitySearchControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsOrganizationLinkAvailabilitySearchControllerNestedResponse_OrganizationLinkError_Result } from "@AssetManagementClient/Primitives/Results/OrganizationLinkAvailabilitySearchControllerNested/Response_/OrganizationLinkError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesRenameControllerOrganizationRenameControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Rename/Controller/OrganizationRenameControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsOrganizationRenameControllerNestedResponse_OrganizationRenameError_Result } from "@AssetManagementClient/Primitives/Results/OrganizationRenameControllerNested/Response_/OrganizationRenameError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesUnlinkControllerOrganizationUnlinkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Unlink/Controller/OrganizationUnlinkControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsOrganizationUnlinkControllerNestedResponse_OrganizationUnlinkError_Result } from "@AssetManagementClient/Primitives/Results/OrganizationUnlinkControllerNested/Response_/OrganizationUnlinkError_.gen"
import { Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummaryFeatureUnlockedSearchControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationsSummary/OrganizationsSummaryFeatureUnlockedSearchControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummaryGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationsSummary/OrganizationsSummaryGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummarySearchControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummarySearchControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationsSummary/OrganizationsSummarySearchControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryPackagesQueryOrganizationsSummarySearchAvailabilitiesNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationsSummary/Packages/Query/OrganizationsSummarySearchAvailabilitiesNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesRegionalSettingsControllerRegionalSettingsAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/RegionalSettings/Controller/RegionalSettingsAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesRegionalSettingsControllerRegionalSettingsInfoGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesRegionalSettingsControllerRegionalSettingsInfoGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/RegionalSettings/Controller/RegionalSettingsInfoGetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesAssetReportAssetTypeControllerReportAssetTypeAvailabilitiesNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/ReportAssetType/Controller/ReportAssetTypeAvailabilitiesNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapAccountGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Roadmap/Controller/RoadmapAccountGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Roadmap/Controller/RoadmapGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapPdfNewControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Roadmap/Controller/RoadmapPdfNewControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardCategory/Controller/ScorecardCategoryCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryDeleteWithoutItemsControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardCategory/Controller/ScorecardCategoryDeleteWithoutItemsControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryDeleteWithItemsControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardCategory/Controller/ScorecardCategoryDeleteWithItemsControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryOrderUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardCategory/Controller/ScorecardCategoryOrderUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardCategory/Controller/ScorecardCategoryUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardLatestGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardLatestGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardLatestGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemAutomationRefreshControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardItem/Controller/ScorecardItemAutomationRefreshControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardPdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardPdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardItem/Controller/ScorecardItemCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemOrderUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardItem/Controller/ScorecardItemOrderUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemRemoveBulkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardItem/Controller/ScorecardItemRemoveBulkControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardItem/Controller/ScorecardItemUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardUpdateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardUpdateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsEnableControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsEnableControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Settings/Controller/SettingsEnableControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Settings/Controller/SettingsGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsResetToDefaultControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsResetToDefaultControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Settings/Controller/SettingsResetToDefaultControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsSaveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Settings/Controller/SettingsSaveControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesSharePackagesRecipientsControllerAvailableRecipientsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Share/Packages/Recipients/Controller/AvailableRecipientsGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesSharePackagesSendControllerShareSendControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesSharePackagesSendControllerShareSendControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Share/Packages/Send/Controller/ShareSendControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsAssetSearchControllerAssetSearchControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsAssetSearchControllerAssetSearchControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/AssetSearch/Controller/AssetSearchControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsControllerInsightDashboardControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsControllerInsightDashboardControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Controller/InsightDashboardControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderAssetCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetFixLogAssignControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetFixLogAssignControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderAssetFixLogAssignControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetOrphanCountControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderAssetOrphanCountControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetFixLogDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderAssetFixLogDeleteControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetFixLogListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderAssetFixLogListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetRemoveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderAssetRemoveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderDisposeControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderDisposeControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderPickupWindowClearControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderPickupWindowClearControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalSettingsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalSettingsGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalSettingsSaveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalSettingsSaveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesCorePackagesTicketControllerTicketCreateFieldAvailabilitiesGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesCorePackagesTicketControllerTicketCreateFieldAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Core/Packages/Ticket/Controller/TicketCreateFieldAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactOrderInfoGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactOrderInfoGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/TreeImpact/Controller/TreeImpactOrderInfoGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactSummaryGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactSummaryGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/TreeImpact/Controller/TreeImpactSummaryGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesAccessPermissionUserAccessPermissionGetByHubUserIdControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesUserPackagesAccessPermissionUserAccessPermissionGetByHubUserIdControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/AccessPermission/UserAccessPermissionGetByHubUserIdControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesUserPackagesActiveUserControllerActiveUserListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/ActiveUser/Controller/ActiveUserListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesUserCreateControllerUserCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/UserCreate/Controller/UserCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesUserDeleteControllerUserDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/UserDelete/Controller/UserDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesUserGrantBulkControllerUserGrantControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/UserGrantBulk/Controller/UserGrantControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesUserPackagesUserListControllerUserListV2ControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/UserList/Controller/UserListV2ControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/OrganizationStar/Controller/UserOrganizationListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarAddControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarAddControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/OrganizationStar/Controller/UserOrganizationStarAddControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/OrganizationStar/Controller/UserOrganizationStarGetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/OrganizationStar/Controller/UserOrganizationStarListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarRemoveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/OrganizationStar/Controller/UserOrganizationStarRemoveControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesUserPackagesPermissionAvailabilitiesControllerUserPermissionAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/PermissionAvailabilities/Controller/UserPermissionAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesPermissionUpdateControllerUserPermissionUpdateV2ControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/PermissionUpdate/Controller/UserPermissionUpdateV2ControllerNested.gen"

//#endregion Imports


//#region Operations

export function brandingMspGet(request: AssetManagementClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesStrategyPackagesBrandingControllerBrandingMspGetControllerNestedResponse>
{
  return callApi<AssetManagementClientCommonControllerControllerEmptyRequest, AssetManagementClientAssetManagementPackagesStrategyPackagesBrandingControllerBrandingMspGetControllerNestedResponse>("POST", "/Branding/Msp/Get", request);
}

export function userAccessPermissionGetByHubUserId(request: AssetManagementClientAssetManagementPackagesUserPackagesAccessPermissionUserAccessPermissionGetByHubUserIdControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesUserPackagesAccessPermissionUserAccessPermissionGetByHubUserIdControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesUserPackagesAccessPermissionUserAccessPermissionGetByHubUserIdControllerNestedRequest, AssetManagementClientAssetManagementPackagesUserPackagesAccessPermissionUserAccessPermissionGetByHubUserIdControllerNestedResponse>("POST", "/User/Access/Permission/Get/By/Hub/User/Id", request);
}

export function userCreate(request: AssetManagementClientAssetManagementPackagesUserPackagesUserCreateControllerUserCreateControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesUserPackagesUserCreateControllerUserCreateControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/User/Create", request);
}

export function userDelete(request: AssetManagementClientAssetManagementPackagesUserPackagesUserDeleteControllerUserDeleteControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesUserPackagesUserDeleteControllerUserDeleteControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/User/Delete", request);
}

export function userGrantBulk(request: AssetManagementClientAssetManagementPackagesUserPackagesUserGrantBulkControllerUserGrantControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesUserPackagesUserGrantBulkControllerUserGrantControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/User/Grant/Bulk", request);
}

export function userListV2(request: AssetManagementClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesUserPackagesUserListControllerUserListV2ControllerNestedResponse>
{
  return callApi<AssetManagementClientCommonControllerControllerEmptyRequest, AssetManagementClientAssetManagementPackagesUserPackagesUserListControllerUserListV2ControllerNestedResponse>("POST", "/User/List/V2", request);
}

export function userPermissionAvailabilitiesGet(request: AssetManagementClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesUserPackagesPermissionAvailabilitiesControllerUserPermissionAvailabilitiesGetControllerNestedResponse>
{
  return callApi<AssetManagementClientCommonControllerControllerEmptyRequest, AssetManagementClientAssetManagementPackagesUserPackagesPermissionAvailabilitiesControllerUserPermissionAvailabilitiesGetControllerNestedResponse>("POST", "/User/Permission/Availabilities/Get", request);
}


//#endregion Operations


