// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.

import axios, { AxiosRequestConfig, Method, AxiosError } from 'axios';
import { sleep } from '~/extensions/packages/sleep/sleep';
import { clientConfig } from '~/client/clientConfig';

const streamSaverPromise = typeof document !== 'undefined'
  // Importing the streamSaver library uses window which crashes in server-side rendering contexts
  ? import('streamsaver')
  : undefined;


//#region ApiCalls


//#region CaseConverters

export function formatRequest(input: any)
{
  const output : any = {};
  let key;
  let updatedKey;
  let value;
  if (input instanceof Array)
  {
    return input.map(function(value)
    {
      if (typeof value === 'object')
      {
        value = formatRequest(value);
      }
      return value;
    });
  }
  else
  {
    for (key in input)
    {
      if (input.hasOwnProperty(key))
      {
        value = input[key];
        if (value instanceof Array || (value !== null && typeof value !== 'undefined' && value.constructor === Object))
        {
          value = formatRequest(value);
        }

        updatedKey = key.includes('-')
          // This is likely not a property but a custom dictionary key (workaround for WM-1555)
          ? key
          : camelToPascal(key);
        output[updatedKey] = value;
      }
    }
  }
  return output;
}

const camelToPascal = (key: string) => (key.charAt(0).toUpperCase() + key.slice(1) || key).toString();
const pascalToCamel = (key: string) => (key.charAt(0).toLowerCase() + key.slice(1) || key).toString();

const formatKey = (key: string) => key.includes('-')
  // This is likely not a property but a custom dictionary key (workaround for WM-1555)
  ? key
  : pascalToCamel(key);

type ValueTransformer = (value: any) => any;

/**
 * It is our convention to use undefined throughout the codebase to reduce
 * confusion and ambiguity between undefined and null.
 *
 * Therefore, we map nulls from the API to undefined for use in Typescript.
 */
const transformNullToUndefined: ValueTransformer = (value) =>
    value === null ? undefined : value;

/**
 * Transformations applied to all json values before passing to the consumer
 */
const valueTransformations: ValueTransformer[]  = [
  transformNullToUndefined
];

const formatValue = <T>(value: T) =>
    // Apply all transformations
    valueTransformations.reduce((value, transform) => transform(value), value);

export function formatResponse(input: any): any
{
  const output: any = {};
  if (input instanceof Array)
  {
    return input.map((value) =>
        typeof value === 'object' && value !== null
            // It's an object or array, recurse
            ? formatResponse(value)
            : formatValue(value));
  }
  else
  {
    for (const key in input)
    {
      if (input.hasOwnProperty(key))
      {
        const value = input[key];

        // Format the key
        const formattedKey = formatKey(key);

        // Format the value
        const formattedValue = typeof value === 'object' && value !== null
          // It's an object or array, recurse
          ? formatResponse(value)
          : formatValue(value);

        // Build output object
        output[formattedKey] = formattedValue;
      }
    }
  }
  return output;
}

//#endregion CaseConverters


const prefix = () => clientConfig.origin + "/api/Sso"

async function callApi<TRequest, TResponse>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<TResponse>
{
  try {
    const axiosRequest = createAxiosRequest(method, path, formatRequest(request), false);
    const response = await axios(axiosRequest);
    return formatResponse(response.data);
  } catch(error) {
    const err = error as AxiosError;
    const csrfToken = err.response?.headers['X-XSRF-TOKEN'.toLowerCase()];
    if (typeof csrfToken !== 'undefined' && attemptCount < 6)
    {
      localStorage.setItem('XSRF-TOKEN', csrfToken);

      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);

      return callApi(method, path, request, attemptCount + 1);
    }
    throw error;
  }
}

async function callStreamApi<TRequest>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<void>
{
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const fetchResponse = await fetch(prefix() + path, {
    method: method,
    headers: {
      ...((csrfToken !== null) && { 'X-XSRF-TOKEN': csrfToken }),
      'Content-Type': 'application/json',
    },
    // Fetch requires this to be a string
    body: JSON.stringify(formatRequest(request)),
  });

  if (!fetchResponse.ok) {
    const csrfToken = fetchResponse.headers.get('X-XSRF-TOKEN'.toLowerCase());
    if (typeof csrfToken !== 'undefined' && attemptCount < 6) {
      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);
      return callStreamApi(method, path, request, attemptCount + 1);
    }
  }

  // Download stream
  const readableStream = fetchResponse.body;
  // Regex to get the filename from the Content-Disposition header
  const fileNameRegex = `(?<=filename=").*?(?=")`;
  const fileName = fetchResponse.headers.get('Content-Disposition')?.match(fileNameRegex)![0];
  const decodedFilename = decodeURIComponent(fileName!);
  if (window.WritableStream && readableStream?.pipeTo) {
    // We can assert StreamSaver exists now as this is a client-side context
    const streamSaver = (await streamSaverPromise!).default;
    // Override mitm.html location from jimmywarting.github.io to self-hosted file in php
    streamSaver.mitm = '/stream-saver/mitm.html';
    const fileStream = streamSaver.createWriteStream(decodedFilename);
    return readableStream.pipeTo(fileStream);
  }
}

function createAxiosRequest<TRequest>(callMethod: Method, path: string, request: TRequest, isStreamRequest: boolean): AxiosRequestConfig
{
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const requestConfig: AxiosRequestConfig =
  {
    method: callMethod,
    url: prefix() + path,
    data: request,
    headers: {
      ...((csrfToken !== null) && {'X-XSRF-TOKEN': csrfToken})
    }
  };
  if (isStreamRequest)
  {
    requestConfig.responseType = 'stream';
    requestConfig.timeout = 99000; // milliseconds
  }
  return requestConfig;
}

//#endregion ApiCalls


//#region Imports

import { Request as SsoClientSsoTokenPackagesAccessPackagesRefreshControllerAccessTokenRefreshControllerNestedRequest } from "@SsoClient/Sso/Token/Packages/Access/Packages/Refresh/Controller/AccessTokenRefreshControllerNested.gen"
import { Result as SsoClientPrimitivesResultsAccessTokenRefreshControllerNestedResponse_InvalidRefreshTokenError_Result } from "@SsoClient/Primitives/Results/AccessTokenRefreshControllerNested/Response_/InvalidRefreshTokenError_.gen"
import { AccountProvisionedAccessDto as SsoClientSsoProductAccessModelAccountProvisionedAccessDto, ProvisionedAccessCreateRequest as SsoClientSsoProductAccessModelProvisionedAccessCreateRequest, ProvisionedAccessCreateResponse as SsoClientSsoProductAccessModelProvisionedAccessCreateResponse } from "@SsoClient/Sso/ProductAccess/Model.gen"
import { Request as SsoClientSsoTokenPackagesApiKeyControllerAccountTokenGenerateControllerNestedRequest, Response as SsoClientSsoTokenPackagesApiKeyControllerAccountTokenGenerateControllerNestedResponse } from "@SsoClient/Sso/Token/Packages/ApiKey/Controller/AccountTokenGenerateControllerNested.gen"
import { Request as SsoClientSsoApiKeyControllerApiKeyExchangeControllerNestedRequest, Response as SsoClientSsoApiKeyControllerApiKeyExchangeControllerNestedResponse } from "@SsoClient/Sso/ApiKey/Controller/ApiKeyExchangeControllerNested.gen"
import { Request as SsoClientSsoApiKeyControllerApiKeyGenerateControllerNestedRequest, Response as SsoClientSsoApiKeyControllerApiKeyGenerateControllerNestedResponse } from "@SsoClient/Sso/ApiKey/Controller/ApiKeyGenerateControllerNested.gen"
import { Request as SsoClientSsoApiKeyPublicControllerApiKeyPublicGenerateControllerNestedRequest, Response as SsoClientSsoApiKeyPublicControllerApiKeyPublicGenerateControllerNestedResponse } from "@SsoClient/Sso/ApiKeyPublic/Controller/ApiKeyPublicGenerateControllerNested.gen"
import { EmptyRequest as SsoClientCommonControllerControllerEmptyRequest, EmptyResponse as SsoClientCommonControllerControllerEmptyResponse } from "@SsoClient/Common/Controller/Controller.gen"
import { Response as SsoClientSsoApiKeyPublicControllerApiKeyPublicListControllerNestedResponse } from "@SsoClient/Sso/ApiKeyPublic/Controller/ApiKeyPublicListControllerNested.gen"
import { Request as SsoClientSsoApiKeyPublicControllerApiKeyPublicUpdateControllerNestedRequest } from "@SsoClient/Sso/ApiKeyPublic/Controller/ApiKeyPublicUpdateControllerNested.gen"
import { Request as SsoClientSsoTokenPackagesCodePackagesExchangeControllerExchangeCodeTokenControllerNestedRequest, Response as SsoClientSsoTokenPackagesCodePackagesExchangeControllerExchangeCodeTokenControllerNestedResponse } from "@SsoClient/Sso/Token/Packages/Code/Packages/Exchange/Controller/ExchangeCodeTokenControllerNested.gen"
import { Response as SsoClientSsoTokenPackagesCodePackagesExchangeControllerGainsightExchangeCodeTokenControllerNestedResponse } from "@SsoClient/Sso/Token/Packages/Code/Packages/Exchange/Controller/GainsightExchangeCodeTokenControllerNested.gen"
import { Request as SsoClientSsoTokenPackagesCodePackagesGenerateControllerGenerateCodeTokenControllerNestedRequest, Response as SsoClientSsoTokenPackagesCodePackagesGenerateControllerGenerateCodeTokenControllerNestedResponse } from "@SsoClient/Sso/Token/Packages/Code/Packages/Generate/Controller/GenerateCodeTokenControllerNested.gen"
import { Request as SsoClientSsoCredentialsPackagesEmailControllerEmailConfirmControllerNestedRequest } from "@SsoClient/Sso/Credentials/Packages/Email/Controller/EmailConfirmControllerNested.gen"
import { Result as SsoClientPrimitivesResultsUserResponse_EmailConfirmError_Result } from "@SsoClient/Primitives/Results/UserResponse_/EmailConfirmError_.gen"
import { Request as SsoClientSsoExternalSigninControllerIdentityProviderSignInControllerNestedRequest, Response as SsoClientSsoExternalSigninControllerIdentityProviderSignInControllerNestedResponse } from "@SsoClient/Sso/ExternalSignin/Controller/IdentityProviderSignInControllerNested.gen"
import { IdentityMeDto as SsoClientSsoIdentityModelIdentityMeDto } from "@SsoClient/Sso/Identity/Model.gen"
import { Request as SsoClientSsoIdentityPackagesUserControllerUserAccessPermissionHubGetControllerNestedRequest, Response as SsoClientSsoIdentityPackagesUserControllerUserAccessPermissionHubGetControllerNestedResponse } from "@SsoClient/Sso/Identity/Packages/User/Controller/UserAccessPermissionHubGetControllerNested.gen"
import { Response as SsoClientSsoIdentityPackagesUserControllerActiveUserListGetControllerNestedResponse } from "@SsoClient/Sso/Identity/Packages/User/Controller/ActiveUserListGetControllerNested.gen"
import { Request as SsoClientSsoIdentityPackagesUserControllerUserCreateControllerNestedRequest } from "@SsoClient/Sso/Identity/Packages/User/Controller/UserCreateControllerNested.gen"
import { Request as SsoClientSsoIdentityPackagesUserControllerUserDeleteControllerNestedRequest } from "@SsoClient/Sso/Identity/Packages/User/Controller/UserDeleteControllerNested.gen"
import { Request as SsoClientSsoIdentityPackagesUserControllerUserGrantControllerNestedRequest } from "@SsoClient/Sso/Identity/Packages/User/Controller/UserGrantControllerNested.gen"
import { Response as SsoClientSsoIdentityControllerUserListControllerNestedResponse } from "@SsoClient/Sso/Identity/Controller/UserListControllerNested.gen"
import { Response as SsoClientSsoIdentityPackagesUserControllerUserListV2ControllerNestedResponse } from "@SsoClient/Sso/Identity/Packages/User/Controller/UserListV2ControllerNested.gen"
import { Response as SsoClientSsoIdentityPackagesUserControllerUserPermissionAvailabilitiesGetControllerNestedResponse } from "@SsoClient/Sso/Identity/Packages/User/Controller/UserPermissionAvailabilitiesGetControllerNested.gen"
import { Request as SsoClientSsoIdentityPackagesUserControllerUserPermissionUpdateV2ControllerNestedRequest } from "@SsoClient/Sso/Identity/Packages/User/Controller/UserPermissionUpdateV2ControllerNested.gen"
import { Request as SsoClientSsoImpersonationPackagesStartControllerImpersonationStartControllerNestedRequest } from "@SsoClient/Sso/Impersonation/Packages/Start/Controller/ImpersonationStartControllerNested.gen"
import { Result as SsoClientPrimitivesResultsImpersonationStartControllerNestedResponse_ImpersonationError_Result } from "@SsoClient/Primitives/Results/ImpersonationStartControllerNested/Response_/ImpersonationError_.gen"
import { Request as SsoClientSsoImpersonationPackagesTerminateControllerImpersonationTerminateControllerNestedRequest } from "@SsoClient/Sso/Impersonation/Packages/Terminate/Controller/ImpersonationTerminateControllerNested.gen"
import { Result as SsoClientPrimitivesResultsImpersonationTerminateControllerNestedResponse_ImpersonationError_Result } from "@SsoClient/Primitives/Results/ImpersonationTerminateControllerNested/Response_/ImpersonationError_.gen"
import { Request as SsoClientSsoCredentialsPackagesMfaPackagesActivateControllerMfaActivateControllerNestedRequest } from "@SsoClient/Sso/Credentials/Packages/Mfa/Packages/Activate/Controller/MfaActivateControllerNested.gen"
import { Result as SsoClientPrimitivesResultsOk_MfaActivateError_Result } from "@SsoClient/Primitives/Results/Ok_/MfaActivateError_.gen"
import { Request as SsoClientSsoCredentialsPackagesMfaPackagesDeactivateControllerMfaDeactivateControllerNestedRequest } from "@SsoClient/Sso/Credentials/Packages/Mfa/Packages/Deactivate/Controller/MfaDeactivateControllerNested.gen"
import { Result as SsoClientPrimitivesResultsOk_MfaDeactivateError_Result } from "@SsoClient/Primitives/Results/Ok_/MfaDeactivateError_.gen"
import { Request as SsoClientSsoCredentialsPackagesPasswordControllerPasswordUpdateControllerNestedRequest } from "@SsoClient/Sso/Credentials/Packages/Password/Controller/PasswordUpdateControllerNested.gen"
import { Ok as SsoClientPrimitivesResultsOk } from "@SsoClient/Primitives/Results.gen"
import { Request as SsoClientSsoProductAccessControllerProductAccessUpdateControllerNestedRequest } from "@SsoClient/Sso/ProductAccess/Controller/ProductAccessUpdateControllerNested.gen"
import { Response as SsoClientBeastClientBeastIdentityControllerProductAccessProductAccessUpdateNestedResponse } from "@SsoClient/BeastClient/Beast/Identity/Controller/ProductAccess/ProductAccessUpdateNested.gen"
import { Request as SsoClientSsoSigninControllerSigninControllerNestedRequest } from "@SsoClient/Sso/Signin/Controller/SigninControllerNested.gen"
import { Result as SsoClientPrimitivesResultsSigninControllerNestedResponse_SigninError_Result } from "@SsoClient/Primitives/Results/SigninControllerNested/Response_/SigninError_.gen"
import { Request as SsoClientSsoSignoutControllerSignoutControllerNestedRequest } from "@SsoClient/Sso/Signout/Controller/SignoutControllerNested.gen"

//#endregion Imports


//#region Operations

export function apiKeyGenerate(request: SsoClientSsoApiKeyControllerApiKeyGenerateControllerNestedRequest): Promise<SsoClientSsoApiKeyControllerApiKeyGenerateControllerNestedResponse>
{
  return callApi<SsoClientSsoApiKeyControllerApiKeyGenerateControllerNestedRequest, SsoClientSsoApiKeyControllerApiKeyGenerateControllerNestedResponse>("POST", "/ApiKey/Generate", request);
}

export function apiKeyPublicGenerate(request: SsoClientSsoApiKeyPublicControllerApiKeyPublicGenerateControllerNestedRequest): Promise<SsoClientSsoApiKeyPublicControllerApiKeyPublicGenerateControllerNestedResponse>
{
  return callApi<SsoClientSsoApiKeyPublicControllerApiKeyPublicGenerateControllerNestedRequest, SsoClientSsoApiKeyPublicControllerApiKeyPublicGenerateControllerNestedResponse>("POST", "/ApiKeyPublic/Generate", request);
}

export function apiKeyPublicList(request: SsoClientCommonControllerControllerEmptyRequest): Promise<SsoClientSsoApiKeyPublicControllerApiKeyPublicListControllerNestedResponse>
{
  return callApi<SsoClientCommonControllerControllerEmptyRequest, SsoClientSsoApiKeyPublicControllerApiKeyPublicListControllerNestedResponse>("POST", "/ApiKeyPublic/List", request);
}

export function apiKeyPublicUpdate(request: SsoClientSsoApiKeyPublicControllerApiKeyPublicUpdateControllerNestedRequest): Promise<SsoClientCommonControllerControllerEmptyResponse>
{
  return callApi<SsoClientSsoApiKeyPublicControllerApiKeyPublicUpdateControllerNestedRequest, SsoClientCommonControllerControllerEmptyResponse>("POST", "/ApiKeyPublic/Update", request);
}

export function codeTokenGenerate(request: SsoClientSsoTokenPackagesCodePackagesGenerateControllerGenerateCodeTokenControllerNestedRequest): Promise<SsoClientSsoTokenPackagesCodePackagesGenerateControllerGenerateCodeTokenControllerNestedResponse>
{
  return callApi<SsoClientSsoTokenPackagesCodePackagesGenerateControllerGenerateCodeTokenControllerNestedRequest, SsoClientSsoTokenPackagesCodePackagesGenerateControllerGenerateCodeTokenControllerNestedResponse>("POST", "/CodeToken/Generate", request);
}

export function identityMe(request: SsoClientCommonControllerControllerEmptyRequest): Promise<SsoClientSsoIdentityModelIdentityMeDto>
{
  return callApi<SsoClientCommonControllerControllerEmptyRequest, SsoClientSsoIdentityModelIdentityMeDto>("POST", "/Identity/Me", request);
}

export function identityUserActiveListGet(request: SsoClientCommonControllerControllerEmptyRequest): Promise<SsoClientSsoIdentityPackagesUserControllerActiveUserListGetControllerNestedResponse>
{
  return callApi<SsoClientCommonControllerControllerEmptyRequest, SsoClientSsoIdentityPackagesUserControllerActiveUserListGetControllerNestedResponse>("POST", "/Identity/User/Active/List/Get", request);
}

export function identityUserPermissionUpdateV2(request: SsoClientSsoIdentityPackagesUserControllerUserPermissionUpdateV2ControllerNestedRequest): Promise<SsoClientCommonControllerControllerEmptyResponse>
{
  return callApi<SsoClientSsoIdentityPackagesUserControllerUserPermissionUpdateV2ControllerNestedRequest, SsoClientCommonControllerControllerEmptyResponse>("POST", "/Identity/User/Permission/Update/V2", request);
}

export function productAccessUpdate(request: SsoClientSsoProductAccessControllerProductAccessUpdateControllerNestedRequest): Promise<SsoClientBeastClientBeastIdentityControllerProductAccessProductAccessUpdateNestedResponse>
{
  return callApi<SsoClientSsoProductAccessControllerProductAccessUpdateControllerNestedRequest, SsoClientBeastClientBeastIdentityControllerProductAccessProductAccessUpdateNestedResponse>("POST", "/ProductAccess/Update", request);
}


//#endregion Operations


