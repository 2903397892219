import { PaginationMetadata, SortState } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import * as React from 'react';
import { useContext } from 'react';
import { ConsoleMetadata, ConsoleState } from './types';
import { produce } from 'immer';
import { resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { css } from '@emotion/react';
import { ColumnAvailabilityDto } from '@AssetManagementClient/BeastClient/Search/Model/Console/Dto.gen';
import DownloadConsoleSpreadsheet from '~/neo-ui/packages/table/packages/console/download/DownloadConsoleSpreadsheet';
import ConsoleSearchBar from '~/neo-ui/packages/table/packages/console/search-bar/ConsoleSearchBar';
import { ConsoleSearch } from '~/neo-ui/packages/table/packages/console/buildConsoleRequest';
import { FieldKey } from '@AssetManagementClient/Primitives/Query.gen';
import { ConsoleRow } from '@AssetManagementClient/BeastClient/Search/Model/Console.gen';
import updateUrlParameter from '~/neo-ui/packages/table/packages/url-routing/updateUrlParameter';
import ConsolePagination from '~/neo-ui/packages/table/packages/console/pagination/ConsolePagination';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { DataTableWrapper } from './DataTableWrapper';
import { FilterBar } from '~/neo-ui/packages/table/packages/console/filter-display/FilterBar';
import ConsoleContext from '~/neo-ui/packages/table/packages/console/contexts/ConsoleContext';
import { FilterResetButton } from '~/neo-ui/packages/table/packages/console/filter-display/FilterResetButton';
import { ToolbarControl } from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import { Enum as SpreadsheetTypeFactoryEnum } from '@AssetManagementClient/Document/Spreadsheet/Model/SpreadsheetTypeFactoryNested.gen';
import Spinner from '~/neo-ui/spinner/Spinner';
import Testable from '~/neo-ui/packages/testable/Testable';
import LayoutHeader from '~/neo-ui/packages/layout/packages/header/LayoutHeader';
import ColumnSelectButton from '~/neo-ui/packages/table/packages/console/column/ColumnSelectButton';

export type ConsoleProps = {
  metadata: ConsoleMetadata | undefined;
  data: {
    consoleRows?: ConsoleRow[];
    consoleIds?: string[];
  };
  onConsoleStateChange: (consoleState: ConsoleState, context: object) => void;
  download?: (
    consoleState: ConsoleState,
    selectedColumns: Set<string> | undefined,
    context: object,
    spreadsheetType: SpreadsheetTypeFactoryEnum,
  ) => void;
  canDownload?: boolean;
  topFilterLine?: React.ReactNode;
  tableHeaderSection?: React.ReactNode;
  hasCheckboxes: boolean;
  noResultsText?: string;
  banner?: JSX.Element;
  reset: () => void;
  extraToolbarButtons?: { control: ToolbarControl; order: number }[];
  legacyDownloadPayload: { url: string; shouldShow: boolean };

  clearFiltersOnFirstLoad?: boolean;
  canSelectDisplayColumns?: boolean;
};

const Console = ({
  metadata,
  data,
  onConsoleStateChange,
  download,
  canDownload = false,
  topFilterLine,
  tableHeaderSection,
  hasCheckboxes,
  noResultsText,
  banner,
  reset,
  extraToolbarButtons = [],
  legacyDownloadPayload,
  clearFiltersOnFirstLoad = false,
  canSelectDisplayColumns = true,
}: ConsoleProps) => {
  const {
    consoleState,
    setConsoleState,
    columns: selectedColumns,
    setColumns: setSelectedColumns,
    selectedConsoleItems: { clearSelectedItems: clearSelectedAssetIds },
    filterFrame,
  } = useContext(ConsoleContext);

  React.useEffect(() => {
    onConsoleStateChange(consoleState, filterFrame);
  }, [consoleState, filterFrame, onConsoleStateChange]);

  const paginationMetadata: PaginationMetadata | undefined = React.useMemo(
    () =>
      typeof metadata?.pagination === 'undefined'
        ? undefined
        : {
            totalResults: metadata.pagination.totalResults,
            totalPages: metadata.pagination.totalPages,
          },
    [metadata?.pagination],
  );

  const onPageNumberChange = React.useCallback(
    (updateFunction: (oldPage: number) => number) => {
      setConsoleState(consoleState =>
        produce(consoleState, (draft: ConsoleState) => {
          draft.pagination.pageNumber = updateFunction(consoleState.pagination.pageNumber);
        }),
      );
    },
    [setConsoleState],
  );

  const backToDefaultPage = React.useCallback(() => onPageNumberChange(_ => 0), [onPageNumberChange]);

  // return to default page if the featureFlag changes
  React.useEffect(() => {
    backToDefaultPage();
  }, [filterFrame, backToDefaultPage]);

  const onSortChange = React.useCallback(
    (sortState: SortState<ConsoleRow> | undefined) => {
      setConsoleState(consoleState =>
        produce(consoleState, (draft: ConsoleState) => {
          draft.sort =
            typeof sortState === 'undefined'
              ? undefined
              : {
                  key: { value: resolveFieldKey(sortState.key) },
                  order: sortState.order,
                };
        }),
      );
    },
    [setConsoleState],
  );

  const onFilterChange = React.useCallback(
    (key: string, value: Map<string, string[]>) => {
      setConsoleState(consoleState =>
        produce(consoleState, (draft: ConsoleState) => {
          if (value.size === 0) {
            draft.parameters.delete(key);
          } else {
            draft.parameters.set(key, value);
          }
        }),
      );
      clearSelectedAssetIds();
      backToDefaultPage();
    },
    [backToDefaultPage, clearSelectedAssetIds, setConsoleState],
  );

  const onSearchChange = React.useCallback(
    (search: string | undefined) => {
      const getVisibleColumns = (): FieldKey[] => {
        const mandatoryColumns =
          metadata?.columns
            .filter(column => column.availability === ColumnAvailabilityDto.Mandatory)
            .sort((a, b) => a.order - b.order)
            .map(
              column =>
                ({
                  value: column.key,
                } as FieldKey),
            ) ?? [];
        const selected = Array.from(selectedColumns ?? []).map(col => ({ value: col } as FieldKey));
        return mandatoryColumns.concat(selected);
      };
      setConsoleState(consoleState =>
        produce(consoleState, (draft: ConsoleState) => {
          if (typeof search === 'undefined') {
            draft.search = undefined;
            return;
          }
          draft.search = {
            columns: getVisibleColumns(),
            value: search,
          } as ConsoleSearch;
        }),
      );
      updateUrlParameter('Search', typeof search === 'undefined' || search === '' ? undefined : search);
      clearSelectedAssetIds();
      backToDefaultPage();
    },
    [backToDefaultPage, clearSelectedAssetIds, metadata?.columns, selectedColumns, setConsoleState],
  );

  const clearFilters = React.useCallback(() => {
    setConsoleState(consoleState =>
      produce(consoleState, (draft: ConsoleState) => {
        draft.parameters.clear();
        draft.search = undefined;
      }),
    );

    reset();
    backToDefaultPage();
  }, [backToDefaultPage, reset, setConsoleState]);

  React.useEffect(() => {
    if (clearFiltersOnFirstLoad) {
      reset();
      clearFilters();
      clearSelectedAssetIds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (typeof metadata === 'undefined' || typeof data.consoleRows === 'undefined' || typeof selectedColumns === 'undefined') {
    return <Spinner />;
  }

  const rightControls =
    typeof download === 'undefined'
      ? extraToolbarButtons
      : extraToolbarButtons?.concat({
          control: {
            expanded: (
              <DownloadConsoleSpreadsheet
                canDownload={canDownload}
                consoleState={consoleState}
                selectedColumns={selectedColumns}
                filterFrame={filterFrame}
                onDownload={download}
                legacyDownloadUrl={legacyDownloadPayload.url}
                shouldShowLegacyDownload={legacyDownloadPayload.shouldShow}
                tooManyAssetsForNonLegacyXlsx={
                  paginationMetadata?.totalResults !== undefined ? paginationMetadata.totalResults > 5000 : false
                }
              />
            ),
          },
          order: 2,
        });

  return (
    <div>
      {rightControls.length > 0 && (
        <LayoutHeader
          css={css`
            background: ${colorToCode('light-000')};
          `}
          rightControls={rightControls.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)).map(button => button.control)}
        />
      )}

      {banner ?? ''}
      <div
        css={css`
          display: flex;
          gap: 0.625rem;
          margin-bottom: 0.625rem;
          align-items: center;
        `}
      >
        {topFilterLine}
        <FilterResetButton
          css={css`
            margin-left: auto;
          `}
          onClick={clearFilters}
        />
      </div>
      <FilterBar
        filters={metadata.filters}
        consoleState={consoleState}
        onFilterChange={onFilterChange}
      />
      <div
        css={css`
          display: flex;
          gap: 0.625rem;
          margin-bottom: 0.625rem;
        `}
      >
        {consoleState.pagination && (
          <ConsolePagination
            defaultPagination={consoleState.pagination}
            paginationMetadata={paginationMetadata ?? 'unknown'}
            paginationState={consoleState.pagination}
            onPageNumberChange={onPageNumberChange}
          />
        )}
      </div>
      <div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            gap: 0.375rem;
            margin-bottom: 0.625rem;
          `}
        >
          {tableHeaderSection && <div>{tableHeaderSection}</div>}
          <div
            css={css`
              flex: 1;
            `}
          >
            <ConsoleSearchBar
              key={'console-search-bar'}
              handleEnterSearchInput={onSearchChange}
              defaultValue={consoleState.search?.value}
            />
          </div>
          {canSelectDisplayColumns && (
            <div>
              <ColumnSelectButton
                selectedColumns={selectedColumns}
                columnOptions={metadata.columns
                  .filter(column => column.availability !== ColumnAvailabilityDto.Mandatory)
                  .sort((a, b) => a.order - b.order)
                  .map(column => ({
                    label: column.label,
                    value: column.key,
                  }))}
                onColumnChange={setSelectedColumns}
              />
            </div>
          )}
        </div>
      </div>
      <Testable testId={`console-data-table-wrapper`}>
        <DataTableWrapper
          data={data}
          metadataColumns={metadata.columns}
          selectedColumns={selectedColumns}
          onSortChange={onSortChange}
          hasCheckboxes={hasCheckboxes}
          noResultsText={noResultsText}
        />
      </Testable>
    </div>
  );
};

export default Console;
