import * as React from 'react';
import FeatureAccessInfoContext, {
  FeatureAccessKey,
  FeatureAccountCheck,
  FeatureOrganizationCheck,
  FeaturePermissionKey,
} from './FeatureAccessInfoContext';
import useFeatureAccessInfoGet from '~/wm/packages/feature/packages/feature-access-banner/hooks/useFeatureAccessInfoGet';
import { CaseEnum as FeatureAccessTypeEnum } from '@BeastClient/Beast/Feature/Packages/Access/Dto/Model/FeatureAccessDtoNested.gen';
import { CaseEnum as FeaturePermissionTypeEnum } from '@BeastClient/Beast/Feature/Packages/Permission/Dto/Model/FeaturePermissionDtoNested.gen';

const FeatureAccessInfoProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const { featureAccessAvailabilities, featurePermissionAvailabilities } = useFeatureAccessInfoGet();

  const hasFeatureAccessAccount: FeatureAccountCheck<FeatureAccessKey> = featureAccessKey => {
    const featureAccess = typeof featureAccessAvailabilities !== 'undefined' ? featureAccessAvailabilities[featureAccessKey] : undefined;

    // Available across account
    return (
      typeof featureAccess !== 'undefined' && featureAccess.isFeatureReleased && featureAccess.enum === FeatureAccessTypeEnum.FullAccess
    );
  };

  const hasFeatureAccessOrganization: FeatureOrganizationCheck<FeatureAccessKey> = (featureAccessKey, organizationId) => {
    if (typeof featureAccessAvailabilities === 'undefined') {
      return false;
    }

    if (hasFeatureAccessAccount(featureAccessKey)) {
      return true;
    }

    const featureAccess = featureAccessAvailabilities[featureAccessKey];

    // Available to a specific organization
    return (
      typeof featureAccess !== 'undefined' &&
      featureAccess.isFeatureReleased &&
      featureAccess.enum === FeatureAccessTypeEnum.LimitedAccess &&
      featureAccess.organizations.some(organization => organization.organizationId === organizationId)
    );
  };

  const hasFeaturePermissionAccount: FeatureAccountCheck<FeaturePermissionKey> = featurePermissionKey => {
    const featurePermission =
      typeof featurePermissionAvailabilities !== 'undefined' ? featurePermissionAvailabilities[featurePermissionKey] : undefined;

    // Available across account
    return (
      typeof featurePermission !== 'undefined' &&
      featurePermission.hasPermission &&
      featurePermission.enum === FeaturePermissionTypeEnum.FullAccess
    );
  };

  const hasFeaturePermissionOrganization: FeatureOrganizationCheck<FeaturePermissionKey> = (featurePermissionKey, organizationId) => {
    if (typeof featurePermissionAvailabilities === 'undefined') {
      return false;
    }

    if (hasFeaturePermissionAccount(featurePermissionKey)) {
      return true;
    }

    const featurePermission = featurePermissionAvailabilities[featurePermissionKey];

    // Available to a specific organization
    return (
      typeof featurePermission !== 'undefined' &&
      featurePermission.enum === FeaturePermissionTypeEnum.LimitedAccess &&
      featurePermission.organizations.some(organization => organization.organizationId === organizationId)
    );
  };

  if (typeof featureAccessAvailabilities === 'undefined') {
    return null;
  }

  return (
    <FeatureAccessInfoContext.Provider
      value={{
        featureAccessAvailabilities,
        hasFeatureAccessAccount,
        hasFeatureAccessOrganization,
        featurePermissionAvailabilities,
        hasFeaturePermissionAccount,
        hasFeaturePermissionOrganization,
      }}
    >
      {children}
    </FeatureAccessInfoContext.Provider>
  );
};

export default FeatureAccessInfoProvider;
