import React, { useCallback } from 'react';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import Button from '~/neo-ui/packages/button/Button';
import WarrantyCartWorkstationsReminder from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-total-cost/packages/warranty-cart-workstations-reminder/WarrantyCartWorkstationsReminder';
import WarrantyCartTreeImpact from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-total-cost/packages/warranty-cart-tree-impact/WarrantyCartTreeImpact';
import WarrantyCartCostLoader from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-total-cost/packages/warranty-cart-cost-loader/WarrantyCartCostLoader';
import routes from '~/router/types/routes';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { Enum as WarrantyType } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';

export type WarrantyCartTotalCostProps = {
  useAdditionalItems: boolean;
  canCheckout: boolean;
};

const WarrantyCartTotalCost: React.FunctionComponent<WarrantyCartTotalCostProps> = ({ useAdditionalItems, canCheckout }) => {
  const { totalCost, formatCostOutput, getSelectedAssetsCount, apiUpsertLoading, organizationId, warrantyType } = useWarrantyCartContext();

  const getCheckoutRoute = useCallback((): string => {
    switch (warrantyType) {
      case WarrantyType.InfrastructureProtection:
        return routes.ORGANIZATION_MARKETPLACE_CHECKOUT_INFRASTRUCTURE_PROTECTION.build([organizationId]);
      case WarrantyType.WorkstationAssurance:
        return routes.ORGANIZATION_MARKETPLACE_CHECKOUT_WORKSTATION_ASSURANCE.build([organizationId]);
      default:
        return routes.ORGANIZATION_WARRANTIES_CHECKOUT.build([organizationId]);
    }
  }, [warrantyType, organizationId]);

  const color = 'positive-400';
  return (
    <div
      css={css`
        margin-top: 1rem;
      `}
    >
      <Header
        size={5}
        color={'dark-000'}
      >
        {useAdditionalItems ? 'YOUR TOTAL COST' : 'TOTAL'}
      </Header>
      {useAdditionalItems && (
        <>
          <WarrantyCartWorkstationsReminder />
          <WarrantyCartTreeImpact />
        </>
      )}
      <div>
        <div
          css={css`
            display: inline-flex;
            align-items: end;
          `}
        >
          <Header
            css={css`
              margin-top: 0.5rem;
            `}
            size={3}
            color={color}
          >
            {formatCostOutput(totalCost)}
          </Header>
          {apiUpsertLoading && <WarrantyCartCostLoader color={colorToCode(color)} />}
        </div>
      </div>
      <Button
        size={'md'}
        theme={'positive'}
        disabled={!getSelectedAssetsCount() || !canCheckout}
        anchor={{
          href: getCheckoutRoute(),
        }}
      >
        Go to checkout
      </Button>
    </div>
  );
};

export default WarrantyCartTotalCost;
