// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
//
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.

/* eslint-disable @typescript-eslint/naming-convention */

/**
 * Names of all the routes available in our React bundle
 * Adding a new route name here will require it in the records below automatically!
 *
 * TODO: These objects will be viewable in our bundle, even internal routes.
 * Consider generating this per-bundle
 */
export type RouteName = | 'ACCOUNT_ACTIVITIES'
| 'ACCOUNT_ADD_EXPANSION'
| 'ACCOUNT_API'
| 'ACCOUNT_BILLING'
| 'ACCOUNT_BILLING_EDIT'
| 'ACCOUNT_BILLING_INVOICE'
| 'ACCOUNT_BILLING_SETUP_INTENT_CREATE'
| 'ACCOUNT_CONFIRM_EXPANSION'
| 'ACCOUNT_COVERAGE_CARD'
| 'ACCOUNT_ENTERPRISE_CONTACT'
| 'ACCOUNT_ENTERPRISE_INTEREST'
| 'ACCOUNT_ERROR_PRODUCT_ONBOARDING_INCOMPLETE'
| 'ACCOUNT_GROW'
| 'ACCOUNT_HARDWARE_SETTINGS'
| 'ACCOUNT_HOME'
| 'ACCOUNT_INSIGHTS'
| 'ACCOUNT_INSIGHTS_SETTINGS'
| 'ACCOUNT_INTEGRATION_ADD'
| 'ACCOUNT_INTEGRATION_ADD_INDEX'
| 'ACCOUNT_INTEGRATION_DELETE'
| 'ACCOUNT_INTEGRATION_EDIT'
| 'ACCOUNT_INTEGRATION_INDEX'
| 'ACCOUNT_INTEGRATION_ISSUE'
| 'ACCOUNT_INTEGRATION_LOG'
| 'ACCOUNT_INTEGRATION_OAUTH'
| 'ACCOUNT_INTEGRATION_OAUTH_EDIT'
| 'ACCOUNT_INTEGRATION_WARNING_CSV'
| 'ACCOUNT_INVOICE'
| 'ACCOUNT_LITE_INDEX'
| 'ACCOUNT_LOGO'
| 'ACCOUNT_LOGO_IMG'
| 'ACCOUNT_OFFER'
| 'ACCOUNT_PAUSED'
| 'ACCOUNT_PUBLIC'
| 'ACCOUNT_QUOTE'
| 'ACCOUNT_RENEWAL_SETTINGS'
| 'ACCOUNT_SCHEDULED_REPORTS_SETTINGS'
| 'ACCOUNT_SETTINGS'
| 'ACCOUNT_SETUP_REQUIRED'
| 'ACCOUNT_SIGNUP'
| 'ACCOUNT_SOFTWARE_CONTACT'
| 'ACCOUNT_UPGRADE'
| 'ACCOUNT_UPGRADE_PREFERENCE'
| 'ACCOUNT_WARRANTY_COVERAGE_SETTINGS'
| 'ACTION_PACK_LANDING'
| 'ACTIVITIES_INDEX'
| 'ASSESSMENT_TEMPLATE_EDIT'
| 'ASSESSMENT_TEMPLATE_SETTINGS'
| 'ASSET_HARDWARE_INDEX'
| 'ASSET_PEOPLE_INDEX'
| 'ASSET_SOFTWARE_INDEX'
| 'ASSET_STORE_CREATE'
| 'ASSET_STORE_DELETE'
| 'ASSET_STORE_EDIT'
| 'ASSET_STORE_INDEX'
| 'ASSET_STORE_LIST'
| 'CALENDLY'
| 'DASHBOARD_ACCOUNT_EXECUTIVE'
| 'DASHBOARD_ACCOUNT_EXECUTIVE_SPREADSHEET'
| 'DASHBOARD_ALL_ACCOUNT_EXECUTIVE_SPREADSHEET'
| 'DASHBOARD_FRONT'
| 'DASHBOARD_FRONT_DISPLAY'
| 'DASHBOARD_GANTT'
| 'DASHBOARD_JOBBIE'
| 'DASHBOARD_LIST'
| 'DASHBOARD_PERFORMANCE'
| 'DASHBOARD_PERFORMANCE_DETAILED_REPORT'
| 'DASHBOARD_PERFORMANCE_EXECUTIVE_SUMMARY'
| 'DASHBOARD_RELEASE_LOG'
| 'DASHBOARD_SOURCE_CONTROL'
| 'DASHBOARD_SYNCGOD'
| 'DASHBOARD_WARRANTY'
| 'DASHBOARD_ZENDESK'
| 'DISPOSAL_INDEX'
| 'DISPOSAL_ORDER_INFO'
| 'DISPOSAL_ORDER_LIST'
| 'EXTERNAL_CLIENT_ENGAGEMENT_URL'
| 'EXTERNAL_FREE_EDITION_URL'
| 'EXTERNAL_HELP_CENTER'
| 'EXTERNAL_PRODUCT_COMMUNITY'
| 'EXTERNAL_PRODUCT_COMMUNITY_TOPIC_NEW'
| 'EXTERNAL_PRODUCT_IDEAS'
| 'EXTERNAL_PRODUCT_UPDATES'
| 'FEATURE_REQUESTS'
| 'HARDWARE_2WAY_FIGHT_SPREADSHEET'
| 'HARDWARE_CONFLICT_SPREADSHEET'
| 'HARDWARE_DEVICES_WITH_NO_ORGANIZATIONS'
| 'HARDWARE_DUPLICATE_SPREADSHEET'
| 'HARDWARE_EXECUTIVE_PDF'
| 'HARDWARE_INDEX'
| 'HARDWARE_JSON'
| 'HARDWARE_LEGACY_SPREADSHEET'
| 'HARDWARE_ORDER'
| 'HARDWARE_ORDER_SUCCESS'
| 'HARDWARE_OVERRIDE'
| 'HARDWARE_REPORT'
| 'HARDWARE_REPORT_REBUILD_ZIP'
| 'HARDWARE_SPREADSHEET'
| 'HARDWARE_VIEW'
| 'HARDWARE_VIEW_SOFTWARE'
| 'HARDWARE_VIEW_SOFTWARE_DETAIL'
| 'HELP_CENTER_ARTICLE'
| 'HELP_CENTER_ARTICLE_BUYING_WARRANTIES'
| 'HELP_CENTER_ARTICLE_CONNECTWISE_MANAGE_INTEGRATION_INSTRUCTIONS'
| 'HELP_CENTER_ARTICLE_DIGITAL_MATURITY_INDEX'
| 'HELP_CENTER_ARTICLE_DISPOSAL_ORDER'
| 'HELP_CENTER_ARTICLE_HARDWARE_SOFTWARE_SIDE_BY_SIDE'
| 'HELP_CENTER_ARTICLE_INITIATIVES'
| 'HELP_CENTER_ARTICLE_INITIATIVES_CREATE'
| 'HELP_CENTER_ARTICLE_INITIATIVES_MANAGE'
| 'HELP_CENTER_ARTICLE_INSIGHTS'
| 'HELP_CENTER_ARTICLE_INTEGRATION'
| 'HELP_CENTER_ARTICLE_MANUAL_IN_APP_EDIT'
| 'HELP_CENTER_ARTICLE_MEMBER_PERMISSIONS'
| 'HELP_CENTER_ARTICLE_NEW_DASHBOARD'
| 'HELP_CENTER_ARTICLE_ROADMAPS'
| 'HELP_CENTER_ARTICLE_SCORECARDS'
| 'HELP_CENTER_ARTICLE_SOFTWARE_ASSET_MANAGEMENT'
| 'HELP_CENTER_ARTICLE_STRATEGY'
| 'HELP_CENTER_ARTICLE_WARRANTY_COVERAGE'
| 'INDEX_DASHBOARD'
| 'INDEX_INDEX'
| 'INDEX_MFA_LOCKED'
| 'INDEX_MFA_SETUP'
| 'INDEX_SIGNIN'
| 'INDEX_SIGNOUT'
| 'INDEX_SOURCECONTROLBOARD'
| 'INDEX_SSO'
| 'INDEX_START_SYNC'
| 'INTEGRATION_ADD'
| 'INTEGRATION_DELETE'
| 'INTEGRATION_EDIT'
| 'INTEGRATION_INDEX'
| 'INTEGRATION_ISSUE'
| 'INTEGRATION_LOG'
| 'INTEGRATION_OAUTH'
| 'INTEGRATION_OAUTH_DONE'
| 'INTEGRATION_OAUTH_EDIT'
| 'INTEGRATION_WARNING_CSV'
| 'MARKETPLACE_COVERED'
| 'MARKETPLACE_INFRASTRUCTURE_PROTECTION'
| 'MARKETPLACE_SERVICE_REQUESTS'
| 'MARKETPLACE_WORKSTATION_ASSURANCE'
| 'MEMBER_DELETE'
| 'MEMBER_DISABLE'
| 'MEMBER_EDIT'
| 'MEMBER_INVITATION_CANCEL'
| 'MEMBER_INVITATION_RESEND'
| 'MEMBER_RESTORE'
| 'MEMBER_SEND_RESET_PASSWORD'
| 'MEMBER_UNDO_EMAIL_CHANGE'
| 'MEMBERS_INDEX'
| 'MEMBERS_INVITE'
| 'NOTIFICATION_PUSH_REGISTER'
| 'NOTIFICATION_SETTINGS'
| 'NOTIFICATION_UPDATE'
| 'ORGANIZATION_ASSESSMENT_EVALUATION_LIST'
| 'ORGANIZATION_ASSESSMENT_EVALUATION_PERFORM'
| 'ORGANIZATION_ASSESSMENT_EVALUATION_RESULTS'
| 'ORGANIZATION_BUDGET_FORECAST'
| 'ORGANIZATION_CUSTOM_SCHEDULED_REPORTS_EMAIL_SETTINGS'
| 'ORGANIZATION_DASHBOARD'
| 'ORGANIZATION_ENGAGEMENT_ACTION_ITEM_LIST'
| 'ORGANIZATION_ENGAGEMENT_LIST'
| 'ORGANIZATION_ENGAGEMENT_NOTE_LIST'
| 'ORGANIZATION_HARDWARE'
| 'ORGANIZATION_HARDWARE_LIFECYCLE_REPORT'
| 'ORGANIZATION_HARDWARE_SETTINGS'
| 'ORGANIZATION_INDEX'
| 'ORGANIZATION_LEGACY_HARDWARE_EXPORT'
| 'ORGANIZATION_MARKETPLACE_CART_INFRASTRUCTURE_PROTECTION'
| 'ORGANIZATION_MARKETPLACE_CART_WORKSTATION_ASSURANCE'
| 'ORGANIZATION_MARKETPLACE_CHECKOUT_INFRASTRUCTURE_PROTECTION'
| 'ORGANIZATION_MARKETPLACE_CHECKOUT_WORKSTATION_ASSURANCE'
| 'ORGANIZATION_NAME_AND_LINKS'
| 'ORGANIZATION_PEOPLE'
| 'ORGANIZATION_REGIONAL_SETTINGS'
| 'ORGANIZATION_ROOT'
| 'ORGANIZATION_SCHEDULED_REPORTS_EMAIL_SETTINGS'
| 'ORGANIZATION_SCHEDULED_REPORTS_PREVIEW_SEND'
| 'ORGANIZATION_SCHEDULED_REPORTS_SETTINGS'
| 'ORGANIZATION_SETTINGS'
| 'ORGANIZATION_SOFTWARE'
| 'ORGANIZATION_SOFTWARE_CATEGORY_LIFECYCLE_REPORT'
| 'ORGANIZATION_SOFTWARE_LIFECYCLE_REPORT'
| 'ORGANIZATION_SOFTWARE_PRODUCT_LIFECYCLE_REPORT'
| 'ORGANIZATION_SOURCES'
| 'ORGANIZATION_STRATEGY_CONTRACT_LIST'
| 'ORGANIZATION_STRATEGY_INSIGHT_VIEW'
| 'ORGANIZATION_STRATEGY_ROADMAP_INITIATIVE_VIEW'
| 'ORGANIZATION_STRATEGY_ROADMAP_PRESENT'
| 'ORGANIZATION_STRATEGY_ROADMAP_VIEW'
| 'ORGANIZATION_STRATEGY_SCORECARD_INDIVIDUAL_VIEW'
| 'ORGANIZATION_STRATEGY_SCORECARD_PRESENT'
| 'ORGANIZATION_STRATEGY_SCORECARD_VIEW'
| 'ORGANIZATION_WARRANTIES_CART_SERVER_NETWORK'
| 'ORGANIZATION_WARRANTIES_CART_WORKSTATION'
| 'ORGANIZATION_WARRANTIES_CHECKOUT'
| 'ORGANIZATION_WARRANTIES_COUPON_CHECK'
| 'ORGANIZATION_WARRANTIES_PROPOSAL_PDF'
| 'ORGANIZATION_WARRANTY_SETTINGS'
| 'PAGES_DPA'
| 'PAGES_PRIVACY'
| 'PAGES_TERMS'
| 'PAGES_TERMS_EXTENDED_DISPOSAL'
| 'PAGES_TERMS_EXTENDED_WARRANTY'
| 'PAGES_TERMS_WORKSTATION_ASSURANCE'
| 'PRODUCT_ROUTE'
| 'PRODUCTS_INDEX'
| 'PROFILE_CONFIRM'
| 'PROFILE_INDEX'
| 'PROFILE_MFA_SETUP_FORM'
| 'PROFILE_RESET'
| 'PROFILE_UNDO_EMAIL_CHANGE'
| 'PUBLIC_PAGE_CONTACT'
| 'PUBLIC_PAGE_ENVIRONMENTAL'
| 'PUBLIC_PAGE_FAIR_BILLING'
| 'PUBLIC_PAGE_FAQ_MANUAL_OVERRIDE'
| 'PUBLIC_PAGE_HOMEPAGE'
| 'PUBLIC_PAGE_INTEGRATIONS'
| 'PUBLIC_PAGE_PRICE_CHANGE_2018'
| 'PUBLIC_PAGE_TECHNICAL_SUPPORT'
| 'SIGNUP_THANK_YOU'
| 'SOFTWARE_INDEX'
| 'STRATEGY_INSIGHT_UNIQUE_ID_VIEW'
| 'STRATEGY_INSIGHT_VIEW'
| 'STRATEGY_INSIGHTS_DASHBOARD'
| 'STRATEGY_ROADMAP_ACCOUNT_VIEW'
| 'SUPPLIER_PURCHASE_ORDER_CLOSE'
| 'SUPPLIER_PURCHASE_ORDER_EXCEL'
| 'SUPPLIER_PURCHASE_ORDER_LIST'
| 'SUPPLIER_PURCHASE_ORDER_PDF'
| 'SUPPLIER_SETTINGS'
| 'SUPPORT_ACCOUNT_EXECUTIVE'
| 'SUPPORT_ARTICLE'
| 'SUPPORT_CHANGELOG'
| 'SUPPORT_DEVICE'
| 'SUPPORT_DEVICE_SENT'
| 'SUPPORT_GET_SERVICE'
| 'SUPPORT_ISSUE'
| 'SUPPORT_ISSUE_DISMISS'
| 'SUPPORT_ISSUE_RESTORE'
| 'SUPPORT_KNOWLEDGE_BASE'
| 'SUPPORT_LEARN_MORE_ANNOUNCEMENT_DISMISS'
| 'SUPPORT_SCHEDULED_REPORTS_OPT_IN'
| 'USER_ADD'
| 'USER_CONFIRM'
| 'USER_DELETE'
| 'USER_DISABLE'
| 'USER_EDIT'
| 'USER_INVITATION_CANCEL'
| 'USER_INVITATION_RESEND'
| 'USER_INVITE_CONFIRM'
| 'USER_LIST'
| 'USER_RESTORE'
| 'USER_SEND_RESET_PASSWORD'
| 'USER_SIGNUP_CONFIRMATION_EXTERNAL_SENT'
| 'USER_SIGNUP_CONFIRMATION_SENT'
| 'USER_UNDO_EMAIL_CHANGE'
| 'WARRANTIES_BUY_COVERAGE'
| 'WARRANTIES_COVERED'
| 'WARRANTIES_SERVICE_REQUESTS'
| 'WARRANTIES_SUBMIT'
| 'WARRANTIES_SUMMARY'
| 'WARRANTIES_TRANSFER';

export type RouteOptionsGeneratedDoNotUse = {
  /**
   * Get the route associated to a routeName
   */
  route: string;
  /**
   * Get the aliased paths of a route
   */
  aliases?: string[];
  /**
   * Build the route by routeName, this will do any required substitutions
   * Order matters
   *
   * TODO: Does not support regex like the php version does
   */
  build: (params?: string[]) => string;
};

const build = (url: string, params: Record<string, string>) =>
  Object.keys(params).reduce((built, paramsKey) => built.replace(`:${paramsKey}`, params[paramsKey]), url);

const routesGeneratedDoNotUse: Record<RouteName, RouteOptionsGeneratedDoNotUse> = {
  ACCOUNT_ACTIVITIES: {
    route: '/account/activities', 
    build: () => '/account/activities',
  },
  ACCOUNT_ADD_EXPANSION: {
    route: '/account/add-expansion/:expansion', 
    build: params =>
      build('/account/add-expansion/:expansion', {
        expansion: params![0],
      }),
  },
  ACCOUNT_API: {
    route: '/account/api', 
    build: () => '/account/api',
  },
  ACCOUNT_BILLING: {
    route: '/account/billing', 
    aliases: ['/order'],
    build: () => '/account/billing',
  },
  ACCOUNT_BILLING_EDIT: {
    route: '/account/billing-edit', 
    build: () => '/account/billing-edit',
  },
  ACCOUNT_BILLING_INVOICE: {
    route: '/account/billing/invoice/:invoiceId', 
    build: params =>
      build('/account/billing/invoice/:invoiceId', {
        invoiceId: params![0],
      }),
  },
  ACCOUNT_BILLING_SETUP_INTENT_CREATE: {
    route: '/account/billing/setup', 
    build: () => '/account/billing/setup',
  },
  ACCOUNT_CONFIRM_EXPANSION: {
    route: '/account/confirm-expansion/:expansion', 
    build: params =>
      build('/account/confirm-expansion/:expansion', {
        expansion: params![0],
      }),
  },
  ACCOUNT_COVERAGE_CARD: {
    route: '/account/coverage-card/:orderNumber', 
    build: params =>
      build('/account/coverage-card/:orderNumber', {
        orderNumber: params![0],
      }),
  },
  ACCOUNT_ENTERPRISE_CONTACT: {
    route: '/support/enterprise-contact', 
    build: () => '/support/enterprise-contact',
  },
  ACCOUNT_ENTERPRISE_INTEREST: {
    route: '/account/enterprise/book-meeting', 
    build: () => '/account/enterprise/book-meeting',
  },
  ACCOUNT_ERROR_PRODUCT_ONBOARDING_INCOMPLETE: {
    route: '/account/error/product-onboarding-incomplete/:productAbbreviation', 
    build: params =>
      build('/account/error/product-onboarding-incomplete/:productAbbreviation', {
        productAbbreviation: params![0],
      }),
  },
  ACCOUNT_GROW: {
    route: '/account/grow', 
    build: () => '/account/grow',
  },
  ACCOUNT_HARDWARE_SETTINGS: {
    route: '/account/hardware/settings', 
    build: () => '/account/hardware/settings',
  },
  ACCOUNT_HOME: {
    route: '/account/home', 
    build: () => '/account/home',
  },
  ACCOUNT_INSIGHTS: {
    route: '/account/insights', 
    build: () => '/account/insights',
  },
  ACCOUNT_INSIGHTS_SETTINGS: {
    route: '/account/insights/settings', 
    build: () => '/account/insights/settings',
  },
  ACCOUNT_INTEGRATION_ADD: {
    route: '/account/integration/add/:integrationUrlSlug', 
    build: params =>
      build('/account/integration/add/:integrationUrlSlug', {
        integrationUrlSlug: params![0],
      }),
  },
  ACCOUNT_INTEGRATION_ADD_INDEX: {
    route: '/account/integration/add', 
    build: () => '/account/integration/add',
  },
  ACCOUNT_INTEGRATION_DELETE: {
    route: '/account/integration/:id/delete', 
    build: params =>
      build('/account/integration/:id/delete', {
        id: params![0],
      }),
  },
  ACCOUNT_INTEGRATION_EDIT: {
    route: '/account/integration/:id/edit', 
    build: params =>
      build('/account/integration/:id/edit', {
        id: params![0],
      }),
  },
  ACCOUNT_INTEGRATION_INDEX: {
    route: '/account/integrations', 
    build: () => '/account/integrations',
  },
  ACCOUNT_INTEGRATION_ISSUE: {
    route: '/account/integrations/issues', 
    build: () => '/account/integrations/issues',
  },
  ACCOUNT_INTEGRATION_LOG: {
    route: '/account/integration/:id/log', 
    build: params =>
      build('/account/integration/:id/log', {
        id: params![0],
      }),
  },
  ACCOUNT_INTEGRATION_OAUTH: {
    route: '/account/integration/oauth/:integrationUrlSlug/add', 
    build: params =>
      build('/account/integration/oauth/:integrationUrlSlug/add', {
        integrationUrlSlug: params![0],
      }),
  },
  ACCOUNT_INTEGRATION_OAUTH_EDIT: {
    route: '/account/integration/oauth', 
    build: () => '/account/integration/oauth',
  },
  ACCOUNT_INTEGRATION_WARNING_CSV: {
    route: '/account/integration/warning-csv', 
    build: () => '/account/integration/warning-csv',
  },
  ACCOUNT_INVOICE: {
    route: '/account/invoice/:invoiceNumber', 
    build: params =>
      build('/account/invoice/:invoiceNumber', {
        invoiceNumber: params![0],
      }),
  },
  ACCOUNT_LITE_INDEX: {
    route: '/account/lite', 
    build: () => '/account/lite',
  },
  ACCOUNT_LOGO: {
    route: '/account/logo', 
    build: () => '/account/logo',
  },
  ACCOUNT_LOGO_IMG: {
    route: '/account/logo/logo.png', 
    build: () => '/account/logo/logo.png',
  },
  ACCOUNT_OFFER: {
    route: '/account/offer', 
    build: () => '/account/offer',
  },
  ACCOUNT_PAUSED: {
    route: '/account/paused', 
    build: () => '/account/paused',
  },
  ACCOUNT_PUBLIC: {
    route: '/account/public', 
    build: () => '/account/public',
  },
  ACCOUNT_QUOTE: {
    route: '/account/quote/:quoteId', 
    build: params =>
      build('/account/quote/:quoteId', {
        quoteId: params![0],
      }),
  },
  ACCOUNT_RENEWAL_SETTINGS: {
    route: '/account/renewal-settings', 
    build: () => '/account/renewal-settings',
  },
  ACCOUNT_SCHEDULED_REPORTS_SETTINGS: {
    route: '/account/scheduled-reports/settings', 
    build: () => '/account/scheduled-reports/settings',
  },
  ACCOUNT_SETTINGS: {
    route: '/account/settings', 
    aliases: ['/account', '/account/preferences'],
    build: () => '/account/settings',
  },
  ACCOUNT_SETUP_REQUIRED: {
    route: '/account/setup-required', 
    build: () => '/account/setup-required',
  },
  ACCOUNT_SIGNUP: {
    route: '/signup', 
    build: () => '/signup',
  },
  ACCOUNT_SOFTWARE_CONTACT: {
    route: '/support/software-contact', 
    build: () => '/support/software-contact',
  },
  ACCOUNT_UPGRADE: {
    route: '/upgrade', 
    build: () => '/upgrade',
  },
  ACCOUNT_UPGRADE_PREFERENCE: {
    route: '/upgrade?product=:productAbbreviation', 
    build: params =>
      build('/upgrade?product=:productAbbreviation', {
        productAbbreviation: params![0],
      }),
  },
  ACCOUNT_WARRANTY_COVERAGE_SETTINGS: {
    route: '/account/warranty/settings', 
    build: () => '/account/warranty/settings',
  },
  ACTION_PACK_LANDING: {
    route: '/action-pack', 
    build: () => '/action-pack',
  },
  ACTIVITIES_INDEX: {
    route: '/activities', 
    build: () => '/activities',
  },
  ASSESSMENT_TEMPLATE_EDIT: {
    route: '/account/assessment/template/:assessmentTemplateId/edit', 
    build: params =>
      build('/account/assessment/template/:assessmentTemplateId/edit', {
        assessmentTemplateId: params![0],
      }),
  },
  ASSESSMENT_TEMPLATE_SETTINGS: {
    route: '/account/assessment/template/settings', 
    build: () => '/account/assessment/template/settings',
  },
  ASSET_HARDWARE_INDEX: {
    route: '/asset/hardware', 
    build: () => '/asset/hardware',
  },
  ASSET_PEOPLE_INDEX: {
    route: '/asset/people', 
    build: () => '/asset/people',
  },
  ASSET_SOFTWARE_INDEX: {
    route: '/software', 
    build: () => '/software',
  },
  ASSET_STORE_CREATE: {
    route: '/asset-store/create', 
    build: () => '/asset-store/create',
  },
  ASSET_STORE_DELETE: {
    route: '/asset-store/:assetStoreId/delete', 
    build: params =>
      build('/asset-store/:assetStoreId/delete', {
        assetStoreId: params![0],
      }),
  },
  ASSET_STORE_EDIT: {
    route: '/asset-store/edit/:assetStoreId', 
    build: params =>
      build('/asset-store/edit/:assetStoreId', {
        assetStoreId: params![0],
      }),
  },
  ASSET_STORE_INDEX: {
    route: '/asset-store', 
    build: () => '/asset-store',
  },
  ASSET_STORE_LIST: {
    route: '/asset-store/list', 
    build: () => '/asset-store/list',
  },
  CALENDLY: {
    route: 'https://calendly.com/:calendlyToken/:eventName', 
    build: params =>
      build('https://calendly.com/:calendlyToken/:eventName', {
        calendlyToken: params![0],
        eventName: params![1],
      }),
  },
  DASHBOARD_ACCOUNT_EXECUTIVE: {
    route: '/dashboard/account-executive/:start/:end', 
    build: params =>
      build('/dashboard/account-executive/:start/:end', {
        start: params![0],
        end: params![1],
      }),
  },
  DASHBOARD_ACCOUNT_EXECUTIVE_SPREADSHEET: {
    route: '/dashboard/account-executive/spreadsheet/:accountExecutiveUserId/:start/:end', 
    build: params =>
      build('/dashboard/account-executive/spreadsheet/:accountExecutiveUserId/:start/:end', {
        accountExecutiveUserId: params![0],
        start: params![1],
        end: params![2],
      }),
  },
  DASHBOARD_ALL_ACCOUNT_EXECUTIVE_SPREADSHEET: {
    route: '/dashboard/account-executive/spreadsheetall/:start/:end', 
    build: params =>
      build('/dashboard/account-executive/spreadsheetall/:start/:end', {
        start: params![0],
        end: params![1],
      }),
  },
  DASHBOARD_FRONT: {
    route: '/dashboard/front/:secretKey', 
    build: params =>
      build('/dashboard/front/:secretKey', {
        secretKey: params![0],
      }),
  },
  DASHBOARD_FRONT_DISPLAY: {
    route: '/dashboard/front-display/:secretKey', 
    build: params =>
      build('/dashboard/front-display/:secretKey', {
        secretKey: params![0],
      }),
  },
  DASHBOARD_GANTT: {
    route: '/dashboard/gantt/:secretKey', 
    build: params =>
      build('/dashboard/gantt/:secretKey', {
        secretKey: params![0],
      }),
  },
  DASHBOARD_JOBBIE: {
    route: '/dashboard/job/:secretKey', 
    build: params =>
      build('/dashboard/job/:secretKey', {
        secretKey: params![0],
      }),
  },
  DASHBOARD_LIST: {
    route: '/dashboard/list/:subjectKey/:start/:end', 
    build: params =>
      build('/dashboard/list/:subjectKey/:start/:end', {
        subjectKey: params![0],
        start: params![1],
        end: params![2],
      }),
  },
  DASHBOARD_PERFORMANCE: {
    route: '/dashboard/performance/:start/:end', 
    build: params =>
      build('/dashboard/performance/:start/:end', {
        start: params![0],
        end: params![1],
      }),
  },
  DASHBOARD_PERFORMANCE_DETAILED_REPORT: {
    route: '/dashboard/performance/detail/:start/:end/:accountExecutiveUserId', 
    build: params =>
      build('/dashboard/performance/detail/:start/:end/:accountExecutiveUserId', {
        start: params![0],
        end: params![1],
        accountExecutiveUserId: params![2],
      }),
  },
  DASHBOARD_PERFORMANCE_EXECUTIVE_SUMMARY: {
    route: '/dashboard/performance/summary/:start/:end/:accountExecutiveUserId', 
    build: params =>
      build('/dashboard/performance/summary/:start/:end/:accountExecutiveUserId', {
        start: params![0],
        end: params![1],
        accountExecutiveUserId: params![2],
      }),
  },
  DASHBOARD_RELEASE_LOG: {
    route: '/dashboard/release-log/:secretKey', 
    build: params =>
      build('/dashboard/release-log/:secretKey', {
        secretKey: params![0],
      }),
  },
  DASHBOARD_SOURCE_CONTROL: {
    route: '/dashboard/source-control/:secretKey', 
    build: params =>
      build('/dashboard/source-control/:secretKey', {
        secretKey: params![0],
      }),
  },
  DASHBOARD_SYNCGOD: {
    route: '/dashboard/sync/:secretKey', 
    build: params =>
      build('/dashboard/sync/:secretKey', {
        secretKey: params![0],
      }),
  },
  DASHBOARD_WARRANTY: {
    route: '/dashboard/warranty/:secretKey', 
    build: params =>
      build('/dashboard/warranty/:secretKey', {
        secretKey: params![0],
      }),
  },
  DASHBOARD_ZENDESK: {
    route: '/dashboard/zendesk/:secretKey', 
    build: params =>
      build('/dashboard/zendesk/:secretKey', {
        secretKey: params![0],
      }),
  },
  DISPOSAL_INDEX: {
    route: '/disposal', 
    build: () => '/disposal',
  },
  DISPOSAL_ORDER_INFO: {
    route: '/disposal/order/info/:disposalOrderId', 
    build: params =>
      build('/disposal/order/info/:disposalOrderId', {
        disposalOrderId: params![0],
      }),
  },
  DISPOSAL_ORDER_LIST: {
    route: '/disposal/order/list', 
    build: () => '/disposal/order/list',
  },
  EXTERNAL_CLIENT_ENGAGEMENT_URL: {
    route: 'https://www.scalepad.com/lifecycle-manager/client-engagement/', 
    build: () => 'https://www.scalepad.com/lifecycle-manager/client-engagement/',
  },
  EXTERNAL_FREE_EDITION_URL: {
    route: 'https://www.scalepad.com/get-lifecycle-manager-pro/', 
    build: () => 'https://www.scalepad.com/get-lifecycle-manager-pro/',
  },
  EXTERNAL_HELP_CENTER: {
    route: 'https://help.lifecyclemanager.com', 
    build: () => 'https://help.lifecyclemanager.com',
  },
  EXTERNAL_PRODUCT_COMMUNITY: {
    route: 'https://community.scalepad.com', 
    build: () => 'https://community.scalepad.com',
  },
  EXTERNAL_PRODUCT_COMMUNITY_TOPIC_NEW: {
    route: 'https://community.scalepad.com/topic/new', 
    build: () => 'https://community.scalepad.com/topic/new',
  },
  EXTERNAL_PRODUCT_IDEAS: {
    route: 'https://community.scalepad.com/ideas?filters%5BproductArea%5D%5B0%5D=Lifecycle%20Manager', 
    build: () => 'https://community.scalepad.com/ideas?filters%5BproductArea%5D%5B0%5D=Lifecycle%20Manager',
  },
  EXTERNAL_PRODUCT_UPDATES: {
    route: 'https://community.scalepad.com/product-updates?filters%5BproductArea%5D%5B0%5D=Lifecycle%20Manager', 
    build: () => 'https://community.scalepad.com/product-updates?filters%5BproductArea%5D%5B0%5D=Lifecycle%20Manager',
  },
  FEATURE_REQUESTS: {
    route: '/feature-requests', 
    build: () => '/feature-requests',
  },
  HARDWARE_2WAY_FIGHT_SPREADSHEET: {
    route: '/hardware/2way-fight-spreadsheet', 
    build: () => '/hardware/2way-fight-spreadsheet',
  },
  HARDWARE_CONFLICT_SPREADSHEET: {
    route: '/hardware/conflict-spreadsheet', 
    build: () => '/hardware/conflict-spreadsheet',
  },
  HARDWARE_DEVICES_WITH_NO_ORGANIZATIONS: {
    route: '/hardware/devices-with-no-clients', 
    build: () => '/hardware/devices-with-no-clients',
  },
  HARDWARE_DUPLICATE_SPREADSHEET: {
    route: '/hardware/duplicate-spreadsheet', 
    build: () => '/hardware/duplicate-spreadsheet',
  },
  HARDWARE_EXECUTIVE_PDF: {
    route: '/hardware/executive-pdf', 
    build: () => '/hardware/executive-pdf',
  },
  HARDWARE_INDEX: {
    route: '/hardware', 
    build: () => '/hardware',
  },
  HARDWARE_JSON: {
    route: '/hardware/json/:cacheBuster', 
    build: params =>
      build('/hardware/json/:cacheBuster', {
        cacheBuster: params![0],
      }),
  },
  HARDWARE_LEGACY_SPREADSHEET: {
    route: '/hardware/spreadsheet/account', 
    build: () => '/hardware/spreadsheet/account',
  },
  HARDWARE_ORDER: {
    route: '/hardware/order/:orderNumber', 
    build: params =>
      build('/hardware/order/:orderNumber', {
        orderNumber: params![0],
      }),
  },
  HARDWARE_ORDER_SUCCESS: {
    route: '/hardware/order-success/:orderNumber', 
    build: params =>
      build('/hardware/order-success/:orderNumber', {
        orderNumber: params![0],
      }),
  },
  HARDWARE_OVERRIDE: {
    route: '/hardware/:id/edit', 
    build: params =>
      build('/hardware/:id/edit', {
        id: params![0],
      }),
  },
  HARDWARE_REPORT: {
    route: '/hardware/report', 
    build: () => '/hardware/report',
  },
  HARDWARE_REPORT_REBUILD_ZIP: {
    route: '/hardware/report/rebuild-zip', 
    build: () => '/hardware/report/rebuild-zip',
  },
  HARDWARE_SPREADSHEET: {
    route: '/hardware/spreadsheet', 
    build: () => '/hardware/spreadsheet',
  },
  HARDWARE_VIEW: {
    route: '/hardware/:id/view', 
    build: params =>
      build('/hardware/:id/view', {
        id: params![0],
      }),
  },
  HARDWARE_VIEW_SOFTWARE: {
    route: '/hardware/:id/software', 
    build: params =>
      build('/hardware/:id/software', {
        id: params![0],
      }),
  },
  HARDWARE_VIEW_SOFTWARE_DETAIL: {
    route: '/software/:softwareId/details', 
    build: params =>
      build('/software/:softwareId/details', {
        softwareId: params![0],
      }),
  },
  HELP_CENTER_ARTICLE: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/:articlePath', 
    build: params =>
      build('https://help.lifecyclemanager.com/hc/en-us/articles/:articlePath', {
        articlePath: params![0],
      }),
  },
  HELP_CENTER_ARTICLE_BUYING_WARRANTIES: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17226966892059', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17226966892059',
  },
  HELP_CENTER_ARTICLE_CONNECTWISE_MANAGE_INTEGRATION_INSTRUCTIONS: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17226900818587', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17226900818587',
  },
  HELP_CENTER_ARTICLE_DIGITAL_MATURITY_INDEX: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17227011317531', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17227011317531',
  },
  HELP_CENTER_ARTICLE_DISPOSAL_ORDER: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17226660874651', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17226660874651',
  },
  HELP_CENTER_ARTICLE_HARDWARE_SOFTWARE_SIDE_BY_SIDE: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17226997558299', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17226997558299',
  },
  HELP_CENTER_ARTICLE_INITIATIVES: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17227023954203', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17227023954203',
  },
  HELP_CENTER_ARTICLE_INITIATIVES_CREATE: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17227036009371', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17227036009371',
  },
  HELP_CENTER_ARTICLE_INITIATIVES_MANAGE: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17227035608219', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17227035608219',
  },
  HELP_CENTER_ARTICLE_INSIGHTS: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17227017458075', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17227017458075',
  },
  HELP_CENTER_ARTICLE_INTEGRATION: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17226952063899', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17226952063899',
  },
  HELP_CENTER_ARTICLE_MANUAL_IN_APP_EDIT: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17226796543387', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17226796543387',
  },
  HELP_CENTER_ARTICLE_MEMBER_PERMISSIONS: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/7884590', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/7884590',
  },
  HELP_CENTER_ARTICLE_NEW_DASHBOARD: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17226721504411', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17226721504411',
  },
  HELP_CENTER_ARTICLE_ROADMAPS: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17226736907931', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17226736907931',
  },
  HELP_CENTER_ARTICLE_SCORECARDS: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17226751443995', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17226751443995',
  },
  HELP_CENTER_ARTICLE_SOFTWARE_ASSET_MANAGEMENT: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17226970612507', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17226970612507',
  },
  HELP_CENTER_ARTICLE_STRATEGY: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17227063585947', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17227063585947',
  },
  HELP_CENTER_ARTICLE_WARRANTY_COVERAGE: {
    route: 'https://help.lifecyclemanager.com/hc/en-us/articles/17226958028955', 
    build: () => 'https://help.lifecyclemanager.com/hc/en-us/articles/17226958028955',
  },
  INDEX_DASHBOARD: {
    route: '/dashboard', 
    build: () => '/dashboard',
  },
  INDEX_INDEX: {
    route: '/', 
    build: () => '/',
  },
  INDEX_MFA_LOCKED: {
    route: '/mfa/locked', 
    build: () => '/mfa/locked',
  },
  INDEX_MFA_SETUP: {
    route: '/mfa/setup', 
    build: () => '/mfa/setup',
  },
  INDEX_SIGNIN: {
    route: '/signin', 
    aliases: ['/login'],
    build: () => '/signin',
  },
  INDEX_SIGNOUT: {
    route: '/signout', 
    build: () => '/signout',
  },
  INDEX_SOURCECONTROLBOARD: {
    route: '/sourcecontrolboard', 
    build: () => '/sourcecontrolboard',
  },
  INDEX_SSO: {
    route: '/sso/:provider', 
    build: params =>
      build('/sso/:provider', {
        provider: params![0],
      }),
  },
  INDEX_START_SYNC: {
    route: '/start-sync', 
    build: () => '/start-sync',
  },
  INTEGRATION_ADD: {
    route: '/integration/add/:integrationUrlSlug', 
    build: params =>
      build('/integration/add/:integrationUrlSlug', {
        integrationUrlSlug: params![0],
      }),
  },
  INTEGRATION_DELETE: {
    route: '/integration/:id/delete', 
    build: params =>
      build('/integration/:id/delete', {
        id: params![0],
      }),
  },
  INTEGRATION_EDIT: {
    route: '/integration/:id/edit', 
    build: params =>
      build('/integration/:id/edit', {
        id: params![0],
      }),
  },
  INTEGRATION_INDEX: {
    route: '/integrations', 
    build: () => '/integrations',
  },
  INTEGRATION_ISSUE: {
    route: '/integrations/issues', 
    build: () => '/integrations/issues',
  },
  INTEGRATION_LOG: {
    route: '/integration/:id/log', 
    build: params =>
      build('/integration/:id/log', {
        id: params![0],
      }),
  },
  INTEGRATION_OAUTH: {
    route: '/integration/oauth/:integrationUrlSlug/add', 
    build: params =>
      build('/integration/oauth/:integrationUrlSlug/add', {
        integrationUrlSlug: params![0],
      }),
  },
  INTEGRATION_OAUTH_DONE: {
    route: '/integration/oauth/done/:id', 
    build: params =>
      build('/integration/oauth/done/:id', {
        id: params![0],
      }),
  },
  INTEGRATION_OAUTH_EDIT: {
    route: '/integration/oauth', 
    build: () => '/integration/oauth',
  },
  INTEGRATION_WARNING_CSV: {
    route: '/integration/warning-csv', 
    build: () => '/integration/warning-csv',
  },
  MARKETPLACE_COVERED: {
    route: '/marketplace/covered', 
    build: () => '/marketplace/covered',
  },
  MARKETPLACE_INFRASTRUCTURE_PROTECTION: {
    route: '/marketplace/infrastructure-protection', 
    build: () => '/marketplace/infrastructure-protection',
  },
  MARKETPLACE_SERVICE_REQUESTS: {
    route: '/marketplace/service-requests', 
    build: () => '/marketplace/service-requests',
  },
  MARKETPLACE_WORKSTATION_ASSURANCE: {
    route: '/marketplace/workstation-assurance', 
    build: () => '/marketplace/workstation-assurance',
  },
  MEMBER_DELETE: {
    route: '/members/:userId/delete', 
    build: params =>
      build('/members/:userId/delete', {
        userId: params![0],
      }),
  },
  MEMBER_DISABLE: {
    route: '/members/:userId/disable', 
    build: params =>
      build('/members/:userId/disable', {
        userId: params![0],
      }),
  },
  MEMBER_EDIT: {
    route: '/members/:userId/edit', 
    build: params =>
      build('/members/:userId/edit', {
        userId: params![0],
      }),
  },
  MEMBER_INVITATION_CANCEL: {
    route: '/members/:userId/invitation/cancel', 
    build: params =>
      build('/members/:userId/invitation/cancel', {
        userId: params![0],
      }),
  },
  MEMBER_INVITATION_RESEND: {
    route: '/members/:userId/invitation/resend', 
    build: params =>
      build('/members/:userId/invitation/resend', {
        userId: params![0],
      }),
  },
  MEMBER_RESTORE: {
    route: '/members/:userId/restore', 
    build: params =>
      build('/members/:userId/restore', {
        userId: params![0],
      }),
  },
  MEMBER_SEND_RESET_PASSWORD: {
    route: '/members/:userId/send-reset-password', 
    build: params =>
      build('/members/:userId/send-reset-password', {
        userId: params![0],
      }),
  },
  MEMBER_UNDO_EMAIL_CHANGE: {
    route: '/members/:userId/undo-email-change', 
    build: params =>
      build('/members/:userId/undo-email-change', {
        userId: params![0],
      }),
  },
  MEMBERS_INDEX: {
    route: '/members', 
    build: () => '/members',
  },
  MEMBERS_INVITE: {
    route: '/members/invite', 
    build: () => '/members/invite',
  },
  NOTIFICATION_PUSH_REGISTER: {
    route: '/notification/push/register', 
    build: () => '/notification/push/register',
  },
  NOTIFICATION_SETTINGS: {
    route: '/notification/settings', 
    build: () => '/notification/settings',
  },
  NOTIFICATION_UPDATE: {
    route: '/notification/update', 
    build: () => '/notification/update',
  },
  ORGANIZATION_ASSESSMENT_EVALUATION_LIST: {
    route: '/clients/:organizationId/assessment/evaluations', 
    build: params =>
      build('/clients/:organizationId/assessment/evaluations', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_ASSESSMENT_EVALUATION_PERFORM: {
    route: '/clients/:organizationId/assessment/evaluations/:assessmentEvaluationId/perform', 
    build: params =>
      build('/clients/:organizationId/assessment/evaluations/:assessmentEvaluationId/perform', {
        organizationId: params![0],
        assessmentEvaluationId: params![1],
      }),
  },
  ORGANIZATION_ASSESSMENT_EVALUATION_RESULTS: {
    route: '/clients/:organizationId/assessment/evaluations/:assessmentEvaluationId/results', 
    build: params =>
      build('/clients/:organizationId/assessment/evaluations/:assessmentEvaluationId/results', {
        organizationId: params![0],
        assessmentEvaluationId: params![1],
      }),
  },
  ORGANIZATION_BUDGET_FORECAST: {
    route: '/clients/:organizationId/budget-forecast', 
    build: params =>
      build('/clients/:organizationId/budget-forecast', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_CUSTOM_SCHEDULED_REPORTS_EMAIL_SETTINGS: {
    route: '/clients/:organizationId/custom-scheduled-report/:autoEmailReportId/edit-template', 
    build: params =>
      build('/clients/:organizationId/custom-scheduled-report/:autoEmailReportId/edit-template', {
        organizationId: params![0],
        autoEmailReportId: params![1],
      }),
  },
  ORGANIZATION_DASHBOARD: {
    route: '/clients/:organizationId/dashboard', 
    build: params =>
      build('/clients/:organizationId/dashboard', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_ENGAGEMENT_ACTION_ITEM_LIST: {
    route: '/clients/:organizationId/engagement/action-items', 
    build: params =>
      build('/clients/:organizationId/engagement/action-items', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_ENGAGEMENT_LIST: {
    route: '/clients/:organizationId/engagement/:engagementType', 
    build: params =>
      build('/clients/:organizationId/engagement/:engagementType', {
        organizationId: params![0],
        engagementType: params![1],
      }),
  },
  ORGANIZATION_ENGAGEMENT_NOTE_LIST: {
    route: '/clients/:organizationId/engagement/notes', 
    build: params =>
      build('/clients/:organizationId/engagement/notes', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_HARDWARE: {
    route: '/clients/:organizationId/hardware', 
    aliases: ['/clients/:organizationId/devices', '/clients/:organizationId/assets'],
    build: params =>
      build('/clients/:organizationId/hardware', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_HARDWARE_LIFECYCLE_REPORT: {
    route: '/clients/:organizationId/hardware-lifecycle-report', 
    aliases: ['/device/company-pdf', '/device/clients-pdf'],
    build: params =>
      build('/clients/:organizationId/hardware-lifecycle-report', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_HARDWARE_SETTINGS: {
    route: '/clients/:organizationId/hardware/settings', 
    build: params =>
      build('/clients/:organizationId/hardware/settings', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_INDEX: {
    route: '/clients', 
    aliases: ['/device/companies', '/device/clients'],
    build: () => '/clients',
  },
  ORGANIZATION_LEGACY_HARDWARE_EXPORT: {
    route: '/clients/:organizationId/hardware/spreadsheet', 
    build: params =>
      build('/clients/:organizationId/hardware/spreadsheet', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_MARKETPLACE_CART_INFRASTRUCTURE_PROTECTION: {
    route: '/clients/:organizationId/marketplace/cart/infrastructure-protection', 
    build: params =>
      build('/clients/:organizationId/marketplace/cart/infrastructure-protection', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_MARKETPLACE_CART_WORKSTATION_ASSURANCE: {
    route: '/clients/:organizationId/marketplace/cart/workstation-assurance', 
    build: params =>
      build('/clients/:organizationId/marketplace/cart/workstation-assurance', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_MARKETPLACE_CHECKOUT_INFRASTRUCTURE_PROTECTION: {
    route: '/clients/:organizationId/marketplace/checkout/infrastructure-protection', 
    build: params =>
      build('/clients/:organizationId/marketplace/checkout/infrastructure-protection', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_MARKETPLACE_CHECKOUT_WORKSTATION_ASSURANCE: {
    route: '/clients/:organizationId/marketplace/checkout/workstation-assurance', 
    build: params =>
      build('/clients/:organizationId/marketplace/checkout/workstation-assurance', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_NAME_AND_LINKS: {
    route: '/clients/:organizationId/name-and-links', 
    build: params =>
      build('/clients/:organizationId/name-and-links', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_PEOPLE: {
    route: '/clients/:organizationId/people', 
    build: params =>
      build('/clients/:organizationId/people', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_REGIONAL_SETTINGS: {
    route: '/clients/:organizationId/regional/settings', 
    build: params =>
      build('/clients/:organizationId/regional/settings', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_ROOT: {
    route: '/clients/:organizationId', 
    build: params =>
      build('/clients/:organizationId', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_SCHEDULED_REPORTS_EMAIL_SETTINGS: {
    route: '/clients/:organizationId/scheduled-reports/edit-template', 
    build: params =>
      build('/clients/:organizationId/scheduled-reports/edit-template', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_SCHEDULED_REPORTS_PREVIEW_SEND: {
    route: '/clients/:organizationId/scheduled-reports/preview/send', 
    build: params =>
      build('/clients/:organizationId/scheduled-reports/preview/send', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_SCHEDULED_REPORTS_SETTINGS: {
    route: '/clients/:organizationId/scheduled-reports', 
    aliases: ['/clients/:organizationId/scheduled-reports/settings'],
    build: params =>
      build('/clients/:organizationId/scheduled-reports', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_SETTINGS: {
    route: '/clients/:organizationId/settings', 
    build: params =>
      build('/clients/:organizationId/settings', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_SOFTWARE: {
    route: '/clients/:organizationId/software', 
    build: params =>
      build('/clients/:organizationId/software', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_SOFTWARE_CATEGORY_LIFECYCLE_REPORT: {
    route: '/clients/:organizationId/category/:insightCategory/report', 
    build: params =>
      build('/clients/:organizationId/category/:insightCategory/report', {
        organizationId: params![0],
        insightCategory: params![1],
      }),
  },
  ORGANIZATION_SOFTWARE_LIFECYCLE_REPORT: {
    route: '/clients/:organizationId/software-lifecycle-report', 
    build: params =>
      build('/clients/:organizationId/software-lifecycle-report', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_SOFTWARE_PRODUCT_LIFECYCLE_REPORT: {
    route: '/clients/:organizationId/product/:insightProduct/report', 
    build: params =>
      build('/clients/:organizationId/product/:insightProduct/report', {
        organizationId: params![0],
        insightProduct: params![1],
      }),
  },
  ORGANIZATION_SOURCES: {
    route: '/clients/:organizationId/sources', 
    build: params =>
      build('/clients/:organizationId/sources', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_STRATEGY_CONTRACT_LIST: {
    route: '/clients/:organizationId/contracts', 
    build: params =>
      build('/clients/:organizationId/contracts', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_STRATEGY_INSIGHT_VIEW: {
    route: '/clients/:organizationId/insights/:insightId', 
    build: params =>
      build('/clients/:organizationId/insights/:insightId', {
        organizationId: params![0],
        insightId: params![1],
      }),
  },
  ORGANIZATION_STRATEGY_ROADMAP_INITIATIVE_VIEW: {
    route: '/clients/:organizationId/roadmap#:initiativeId', 
    build: params =>
      build('/clients/:organizationId/roadmap#:initiativeId', {
        organizationId: params![0],
        initiativeId: params![1],
      }),
  },
  ORGANIZATION_STRATEGY_ROADMAP_PRESENT: {
    route: '/clients/:organizationId/roadmap/present', 
    build: params =>
      build('/clients/:organizationId/roadmap/present', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_STRATEGY_ROADMAP_VIEW: {
    route: '/clients/:organizationId/roadmap', 
    build: params =>
      build('/clients/:organizationId/roadmap', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_STRATEGY_SCORECARD_INDIVIDUAL_VIEW: {
    route: '/clients/:organizationId/scorecard/:scorecardId/view', 
    build: params =>
      build('/clients/:organizationId/scorecard/:scorecardId/view', {
        organizationId: params![0],
        scorecardId: params![1],
      }),
  },
  ORGANIZATION_STRATEGY_SCORECARD_PRESENT: {
    route: '/clients/:organizationId/scorecard/present', 
    build: params =>
      build('/clients/:organizationId/scorecard/present', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_STRATEGY_SCORECARD_VIEW: {
    route: '/clients/:organizationId/scorecard', 
    build: params =>
      build('/clients/:organizationId/scorecard', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_WARRANTIES_CART_SERVER_NETWORK: {
    route: '/clients/:organizationId/warranties/cart/server-network', 
    aliases: ['/clients/:organizationId/coverage/cart/server-network', '/clients/:organizationId/coverage/cart'],
    build: params =>
      build('/clients/:organizationId/warranties/cart/server-network', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_WARRANTIES_CART_WORKSTATION: {
    route: '/clients/:organizationId/warranties/cart/workstation', 
    aliases: ['/clients/:organizationId/coverage/cart/workstation'],
    build: params =>
      build('/clients/:organizationId/warranties/cart/workstation', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_WARRANTIES_CHECKOUT: {
    route: '/clients/:organizationId/warranties/checkout', 
    aliases: ['/device/checkout/:organizationId', '/clients/:organizationId/coverage/checkout'],
    build: params =>
      build('/clients/:organizationId/warranties/checkout', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_WARRANTIES_COUPON_CHECK: {
    route: '/clients/:organizationId/warranties/coupon-check', 
    build: params =>
      build('/clients/:organizationId/warranties/coupon-check', {
        organizationId: params![0],
      }),
  },
  ORGANIZATION_WARRANTIES_PROPOSAL_PDF: {
    route: '/clients/warranties/:estimateId/proposal-pdf', 
    aliases: ['/device/proposal-pdf/:estimateId', '/clients/coverage/:estimateId/proposal-pdf'],
    build: params =>
      build('/clients/warranties/:estimateId/proposal-pdf', {
        estimateId: params![0],
      }),
  },
  ORGANIZATION_WARRANTY_SETTINGS: {
    route: '/clients/:organizationId/warranty/settings', 
    build: params =>
      build('/clients/:organizationId/warranty/settings', {
        organizationId: params![0],
      }),
  },
  PAGES_DPA: {
    route: '/pages/DPA', 
    build: () => '/pages/DPA',
  },
  PAGES_PRIVACY: {
    route: '/pages/privacy', 
    build: () => '/pages/privacy',
  },
  PAGES_TERMS: {
    route: '/pages/terms', 
    build: () => '/pages/terms',
  },
  PAGES_TERMS_EXTENDED_DISPOSAL: {
    route: '/pages/terms-extended-disposal-services', 
    build: () => '/pages/terms-extended-disposal-services',
  },
  PAGES_TERMS_EXTENDED_WARRANTY: {
    route: '/pages/terms-extended-warranty-services', 
    build: () => '/pages/terms-extended-warranty-services',
  },
  PAGES_TERMS_WORKSTATION_ASSURANCE: {
    route: '/pages/terms-workstation-assurance', 
    build: () => '/pages/terms-workstation-assurance',
  },
  PRODUCT_ROUTE: {
    route: '/product/:path', 
    build: params =>
      build('/product/:path', {
        path: params![0],
      }),
  },
  PRODUCTS_INDEX: {
    route: '/products', 
    build: () => '/products',
  },
  PROFILE_CONFIRM: {
    route: '/profile/confirm/:token', 
    build: params =>
      build('/profile/confirm/:token', {
        token: params![0],
      }),
  },
  PROFILE_INDEX: {
    route: '/profile/settings', 
    aliases: ['/profile/:dink'],
    build: () => '/profile/settings',
  },
  PROFILE_MFA_SETUP_FORM: {
    route: '/profile/mfa/setup', 
    build: () => '/profile/mfa/setup',
  },
  PROFILE_RESET: {
    route: '/profile/reset/:token', 
    build: params =>
      build('/profile/reset/:token', {
        token: params![0],
      }),
  },
  PROFILE_UNDO_EMAIL_CHANGE: {
    route: '/profile/undo-email-change', 
    build: () => '/profile/undo-email-change',
  },
  PUBLIC_PAGE_CONTACT: {
    route: 'https://www.scalepad.com/contact-us', 
    build: () => 'https://www.scalepad.com/contact-us',
  },
  PUBLIC_PAGE_ENVIRONMENTAL: {
    route: 'https://www.scalepad.com/company/environmental', 
    build: () => 'https://www.scalepad.com/company/environmental',
  },
  PUBLIC_PAGE_FAIR_BILLING: {
    route: 'https://www.scalepad.com/fair-billing', 
    build: () => 'https://www.scalepad.com/fair-billing',
  },
  PUBLIC_PAGE_FAQ_MANUAL_OVERRIDE: {
    route: 'https://www.scalepad.com/faq#remote-override', 
    build: () => 'https://www.scalepad.com/faq#remote-override',
  },
  PUBLIC_PAGE_HOMEPAGE: {
    route: 'https://www.scalepad.com/', 
    build: () => 'https://www.scalepad.com/',
  },
  PUBLIC_PAGE_INTEGRATIONS: {
    route: 'https://www.scalepad.com/integrations', 
    build: () => 'https://www.scalepad.com/integrations',
  },
  PUBLIC_PAGE_PRICE_CHANGE_2018: {
    route: 'https://www.scalepad.com/blog/pricing-and-feature-update', 
    build: () => 'https://www.scalepad.com/blog/pricing-and-feature-update',
  },
  PUBLIC_PAGE_TECHNICAL_SUPPORT: {
    route: 'https://www.scalepad.com/technical-support', 
    build: () => 'https://www.scalepad.com/technical-support',
  },
  SIGNUP_THANK_YOU: {
    route: '/signup-thanks', 
    build: () => '/signup-thanks',
  },
  SOFTWARE_INDEX: {
    route: '/software/client/:organizationId', 
    build: params =>
      build('/software/client/:organizationId', {
        organizationId: params![0],
      }),
  },
  STRATEGY_INSIGHT_UNIQUE_ID_VIEW: {
    route: '/insights/uid/:uniqueId', 
    build: params =>
      build('/insights/uid/:uniqueId', {
        uniqueId: params![0],
      }),
  },
  STRATEGY_INSIGHT_VIEW: {
    route: '/insights/:insightId', 
    build: params =>
      build('/insights/:insightId', {
        insightId: params![0],
      }),
  },
  STRATEGY_INSIGHTS_DASHBOARD: {
    route: '/strategy', 
    aliases: ['/strategy/insights'],
    build: () => '/strategy',
  },
  STRATEGY_ROADMAP_ACCOUNT_VIEW: {
    route: '/strategy/roadmap', 
    build: () => '/strategy/roadmap',
  },
  SUPPLIER_PURCHASE_ORDER_CLOSE: {
    route: '/supplier/purchase-order/:purchaseOrderId/close', 
    build: params =>
      build('/supplier/purchase-order/:purchaseOrderId/close', {
        purchaseOrderId: params![0],
      }),
  },
  SUPPLIER_PURCHASE_ORDER_EXCEL: {
    route: '/supplier/purchase-order/:purchaseOrderId/:purchaseOrderBucket/:purchaseOrderDate/excel', 
    build: params =>
      build('/supplier/purchase-order/:purchaseOrderId/:purchaseOrderBucket/:purchaseOrderDate/excel', {
        purchaseOrderId: params![0],
        purchaseOrderBucket: params![1],
        purchaseOrderDate: params![2],
      }),
  },
  SUPPLIER_PURCHASE_ORDER_LIST: {
    route: '/supplier/purchase-order/:status/list', 
    build: params =>
      build('/supplier/purchase-order/:status/list', {
        status: params![0],
      }),
  },
  SUPPLIER_PURCHASE_ORDER_PDF: {
    route: '/supplier/purchase-order/:purchaseOrderId/:purchaseOrderBucket/:purchaseOrderDate/pdf', 
    build: params =>
      build('/supplier/purchase-order/:purchaseOrderId/:purchaseOrderBucket/:purchaseOrderDate/pdf', {
        purchaseOrderId: params![0],
        purchaseOrderBucket: params![1],
        purchaseOrderDate: params![2],
      }),
  },
  SUPPLIER_SETTINGS: {
    route: '/supplier/settings', 
    build: () => '/supplier/settings',
  },
  SUPPORT_ACCOUNT_EXECUTIVE: {
    route: '/support/account-executive', 
    build: () => '/support/account-executive',
  },
  SUPPORT_ARTICLE: {
    route: '/support/article/:id', 
    build: params =>
      build('/support/article/:id', {
        id: params![0],
      }),
  },
  SUPPORT_CHANGELOG: {
    route: '/support/changelog', 
    build: () => '/support/changelog',
  },
  SUPPORT_DEVICE: {
    route: '/support/device/:serialNumber', 
    build: params =>
      build('/support/device/:serialNumber', {
        serialNumber: params![0],
      }),
  },
  SUPPORT_DEVICE_SENT: {
    route: '/support/device-sent', 
    build: () => '/support/device-sent',
  },
  SUPPORT_GET_SERVICE: {
    route: '/support/get-service/:serialNumber', 
    build: params =>
      build('/support/get-service/:serialNumber', {
        serialNumber: params![0],
      }),
  },
  SUPPORT_ISSUE: {
    route: '/support/issue', 
    build: () => '/support/issue',
  },
  SUPPORT_ISSUE_DISMISS: {
    route: '/support/issue-status/:entryType/dismiss', 
    build: params =>
      build('/support/issue-status/:entryType/dismiss', {
        entryType: params![0],
      }),
  },
  SUPPORT_ISSUE_RESTORE: {
    route: '/support/issue-status/:entryType/restore', 
    build: params =>
      build('/support/issue-status/:entryType/restore', {
        entryType: params![0],
      }),
  },
  SUPPORT_KNOWLEDGE_BASE: {
    route: '/support/knowledge-base', 
    build: () => '/support/knowledge-base',
  },
  SUPPORT_LEARN_MORE_ANNOUNCEMENT_DISMISS: {
    route: '/support/announcement/dismiss/:num', 
    build: params =>
      build('/support/announcement/dismiss/:num', {
        num: params![0],
      }),
  },
  SUPPORT_SCHEDULED_REPORTS_OPT_IN: {
    route: '/support/scheduled-reports/opt-in/:token/:flag', 
    build: params =>
      build('/support/scheduled-reports/opt-in/:token/:flag', {
        token: params![0],
        flag: params![1],
      }),
  },
  USER_ADD: {
    route: '/account/members/invite', 
    aliases: ['/users/add'],
    build: () => '/account/members/invite',
  },
  USER_CONFIRM: {
    route: '/users/confirmation/:token', 
    build: params =>
      build('/users/confirmation/:token', {
        token: params![0],
      }),
  },
  USER_DELETE: {
    route: '/users/:userId/delete', 
    build: params =>
      build('/users/:userId/delete', {
        userId: params![0],
      }),
  },
  USER_DISABLE: {
    route: '/users/:userId/disable', 
    build: params =>
      build('/users/:userId/disable', {
        userId: params![0],
      }),
  },
  USER_EDIT: {
    route: '/users/:userId/edit', 
    build: params =>
      build('/users/:userId/edit', {
        userId: params![0],
      }),
  },
  USER_INVITATION_CANCEL: {
    route: '/users/:userId/invitation/cancel', 
    build: params =>
      build('/users/:userId/invitation/cancel', {
        userId: params![0],
      }),
  },
  USER_INVITATION_RESEND: {
    route: '/users/:userId/invitation/resend', 
    build: params =>
      build('/users/:userId/invitation/resend', {
        userId: params![0],
      }),
  },
  USER_INVITE_CONFIRM: {
    route: '/invitation/:token', 
    build: params =>
      build('/invitation/:token', {
        token: params![0],
      }),
  },
  USER_LIST: {
    route: '/members/settings', 
    aliases: ['/users'],
    build: () => '/members/settings',
  },
  USER_RESTORE: {
    route: '/users/:userId/restore', 
    build: params =>
      build('/users/:userId/restore', {
        userId: params![0],
      }),
  },
  USER_SEND_RESET_PASSWORD: {
    route: '/users/:userId/send-reset-password', 
    build: params =>
      build('/users/:userId/send-reset-password', {
        userId: params![0],
      }),
  },
  USER_SIGNUP_CONFIRMATION_EXTERNAL_SENT: {
    route: '/signup/confirmation-website/:email', 
    build: params =>
      build('/signup/confirmation-website/:email', {
        email: params![0],
      }),
  },
  USER_SIGNUP_CONFIRMATION_SENT: {
    route: '/signup/confirmation/:email', 
    build: params =>
      build('/signup/confirmation/:email', {
        email: params![0],
      }),
  },
  USER_UNDO_EMAIL_CHANGE: {
    route: '/users/:userId/undo-email-change', 
    build: params =>
      build('/users/:userId/undo-email-change', {
        userId: params![0],
      }),
  },
  WARRANTIES_BUY_COVERAGE: {
    route: '/warranties/buy-coverage', 
    aliases: ['/clients/buy-coverage', '/device/companies-renewals', '/device/clients-renewals', '/clients/renewals'],
    build: () => '/warranties/buy-coverage',
  },
  WARRANTIES_COVERED: {
    route: '/warranties/covered', 
    build: () => '/warranties/covered',
  },
  WARRANTIES_SERVICE_REQUESTS: {
    route: '/warranties/service-requests', 
    aliases: ['/warranties/open'],
    build: () => '/warranties/service-requests',
  },
  WARRANTIES_SUBMIT: {
    route: '/warranties/get-service/:assetAccountId', 
    build: params =>
      build('/warranties/get-service/:assetAccountId', {
        assetAccountId: params![0],
      }),
  },
  WARRANTIES_SUMMARY: {
    route: '/warranties/summary/:serviceRequestId', 
    build: params =>
      build('/warranties/summary/:serviceRequestId', {
        serviceRequestId: params![0],
      }),
  },
  WARRANTIES_TRANSFER: {
    route: '/warranties/transfer/:serviceRequestId', 
    build: params =>
      build('/warranties/transfer/:serviceRequestId', {
        serviceRequestId: params![0],
      }),
  },
};

export default routesGeneratedDoNotUse;
