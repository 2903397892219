import { useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { featureAccessInfoGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { featureAvailabilitiesGet } from '@BeastClient/BeastClientPublic.gen';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';
import { FeatureAccessDto } from '@BeastClient/Beast/Feature/Packages/Access/Dto/Model.gen';
import { FeaturePermissionDto } from '@BeastClient/Beast/Feature/Packages/Permission/Dto/Model.gen';

export type FeatureAccessAvailabilities = { [featureAccessKey: string]: FeatureAccessDto };
export type FeaturePermissionAvailabilities = { [featurePermissionsKey: string]: FeaturePermissionDto };

export type UseFeatureAccessInfo = {
  featureAccessAvailabilities: FeatureAccessAvailabilities | undefined;
  featurePermissionAvailabilities: FeaturePermissionAvailabilities | undefined;
};

const useFeatureAccessInfoGet = (): UseFeatureAccessInfo => {
  const { 'lm-essential': enabledLmEssential } = useFeatureFlagProvider();

  const [featureAccessAvailabilities, setFeatureAccessAvailabilities] = useState<FeatureAccessAvailabilities | undefined>();
  const [featurePermissionAvailabilities, setFeaturePermissionAvailabilities] = useState<FeaturePermissionAvailabilities | undefined>();
  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      let response:
        | {
            featureAccessList: FeatureAccessDto[];
            featurePermissionList: FeaturePermissionDto[];
          }
        | undefined;

      if (enabledLmEssential) {
        const availabilitiesGetResponse = await callApi(() => featureAvailabilitiesGet({}));
        response =
          typeof availabilitiesGetResponse !== 'undefined'
            ? {
                featureAccessList: availabilitiesGetResponse.featureAccessList,
                featurePermissionList: availabilitiesGetResponse.featurePermissionList,
              }
            : undefined;
      } else {
        const infoGetResponse = await callApi(() => featureAccessInfoGet({}));
        response =
          typeof infoGetResponse !== 'undefined'
            ? {
                featureAccessList: infoGetResponse.featureAccessAvailabilities,
                featurePermissionList: [],
              }
            : undefined;
      }

      if (!response) {
        return;
      }

      const featureAccessAvailabilitiesDict = response.featureAccessList.reduce((acc, featureAccess) => {
        acc[featureAccess.featureKey] = featureAccess;
        return acc;
      }, {} as FeatureAccessAvailabilities);
      const featurePermissionAvailabilitiesDict = response.featurePermissionList.reduce((acc, featurePermission) => {
        acc[featurePermission.featurePermissionKey] = featurePermission;
        return acc;
      }, {} as FeaturePermissionAvailabilities);

      setFeatureAccessAvailabilities(featureAccessAvailabilitiesDict);
      setFeaturePermissionAvailabilities(featurePermissionAvailabilitiesDict);
    })();
  }, [callApi, enabledLmEssential]);

  return {
    featureAccessAvailabilities,
    featurePermissionAvailabilities,
  };
};

export default useFeatureAccessInfoGet;
