import * as React from 'react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { DisposalOrderAssetDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderAsset/Dto/Model.gen';
import useDisposalOrderAssetSelectContext from '../context/hooks/useDisposalOrderAssetSelectContext';
import getDisposalOrderAssetTableColumns from './table/getDisposalOrderAssetTableColumns';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import TableSelectable from '~/neo-ui/packages/table/packages/table-selectable/TableSelectable';
import { css } from '@emotion/react';

export type DisposalOrderSummarySectionAssetTableProps = {
  disposalOrderAssets: DisposalOrderAssetDto[];
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[];
};

const DisposalOrderSummarySectionAssetTableSelectable = ({
  disposalOrderAssets,
  disposalAssetTypeAvailabilities,
}: DisposalOrderSummarySectionAssetTableProps) => {
  const columns = getDisposalOrderAssetTableColumns(disposalAssetTypeAvailabilities);
  const [currentPage, setCurrentPage] = React.useState(0);

  const { updateSelectedDisposalOrderAssetIds } = useDisposalOrderAssetSelectContext();

  return (
    <TableSelectable
      data={disposalOrderAssets}
      dataKey={'disposalOrderAssetId'}
      columns={columns}
      dataTableProps={{
        pagination: { pageSize: 10, pageIndex: currentPage },
        onPageChange: pagination => setCurrentPage(pagination.pageIndex),
        children: ({ Table }) => (
          <div>
            <Header
              size={4}
              weight={'bold'}
              css={css`
                margin-bottom: 1rem;
              `}
            >
              {disposalOrderAssets.length} asset
              {disposalOrderAssets.length === 1 ? '' : 's'}
            </Header>
            <Table />
          </div>
        ),
      }}
      updateSelectedIds={updateSelectedDisposalOrderAssetIds}
    />
  );
};

export default DisposalOrderSummarySectionAssetTableSelectable;
