import React, { useContext } from 'react';
import useAssetConsoleRequest from '~/wm/packages/asset/packages/console/hooks/useAssetConsoleRequest';
import AsyncConsole from '~/neo-ui/packages/table/packages/console/AsyncConsole';
import useDownloadAssetSpreadsheet from '~/wm/packages/asset/packages/console/hooks/useDownloadAssetSpreadsheet';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { Enum as AssetScopeEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/AssetScope/Logic/AssetScopeFactoryNested.gen';
import ForecastFilter, {
  getFilterFrameFromUrl,
} from '~/wm/packages/asset/packages/console/packages/forecast/packages/forecast-filter/ForecastFilter';
import AddToInitiativeButton from '~/wm/packages/strategy/packages/initiative/packages/add-to-initiative/AddToInitiativeButton';
import { Enum as ScopeEnum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import StrategyDataCollectionSources from '~/wm/packages/strategy/model/strategyDataCollectionSources';
import SelectedDisplayCount from '~/neo-ui/packages/table/packages/console/checkboxes/SelectedDisplayCount';
import { css } from '@emotion/react';
import { borderRadiusToCode } from '~/neo-ui/packages/style/BorderRadius';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import ConsoleShareButton, {
  ConsoleShareButtonFrontendProps,
} from '~/wm/packages/hardware/packages/console-share-button/ConsoleShareButton';
import AssetConsoleUpgradeBanner from '~/wm/packages/asset/packages/console/packages/banner/AssetConsoleUpgradeBanner';
import useConsole from '~/neo-ui/packages/table/packages/console/hook/useConsole';
import ConsoleContext from '~/neo-ui/packages/table/packages/console/contexts/ConsoleContext';
import AssetInsightDropdown from '~/wm/packages/asset/packages/console/packages/insight/AssetInsightDropdown';
import { CategoryPayload } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Asset/Model/Dto/InsightOptionPayloadNested.gen';
import updateUrlParameter from '~/neo-ui/packages/table/packages/url-routing/updateUrlParameter';
import SettingsLink from '~/wm/packages/settings/packages/settings-link/SettingsLink';
import { ToolbarControl } from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import { InsightOptionPayload } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Asset/Model/Dto.gen';
import InsightInformation from '~/wm/packages/strategy/packages/initiative/packages/add-to-initiative/types/InsightInformation';
import { DisplayFeaturesEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/AssetScope/Model/AssetScopeNested.gen';
import { Response as RowResponse } from '@AssetManagementClient/AssetManagement/Packages/Asset/Console/Controller/AssetConsoleControllerNested.gen';
import LifecycleReportButton from '~/neo-ui/packages/table/packages/console/download/packages/lifecycle-report-button/LifecycleReportButton';
import LayoutFooter from '~/neo-ui/packages/layout/packages/footer/LayoutFooter';
import RegionalSettingsInfoProvider from '~/wm/packages/settings/packages/regional-settings/context/RegionalSettingsInfoProvider';
import useFeatureAccessInfoContext from '~/wm/packages/feature/context/hooks/useFeatureAccessInfoContext';

export type AssetConsoleProps = {
  assetType: AssetScopeEnum;
  frontendScope: FrontendScope;
  canDownload: boolean;

  /**
   *  Props for add-to-initiative
   */
  consoleShareProps: ConsoleShareButtonFrontendProps;
  upgradeUrl?: string;
  clientName?: string;
  assetTypeName?: string;
  lifecycleReportUrl?: string;
  clientSettingsUrl?: string;
  legacyDownloadUrl: string;
};

const AssetConsole: React.FunctionComponent<AssetConsoleProps> = ({
  assetType,
  frontendScope,
  canDownload,
  consoleShareProps,
  upgradeUrl,
  clientName,
  assetTypeName = 'assets',
  lifecycleReportUrl,
  clientSettingsUrl,
  legacyDownloadUrl,
}) => {
  const hasOrganizationScope = frontendScope.type === ScopeEnum.Organization;
  const orgId = hasOrganizationScope ? frontendScope.organizationId : undefined;

  const [insightOptions, setInsightOptions] = React.useState<CategoryPayload[]>();

  const [selectedInsight, setSelectedInsight] = React.useState<InsightOptionPayload | undefined>();

  const { reloadAssetRows, reloadIds } = useAssetConsoleRequest(assetType, frontendScope);

  // A function to run after receiving the console response
  const onConsoleResponse = React.useCallback(
    (response: RowResponse | undefined) => {
      if (typeof insightOptions === 'undefined') {
        const options = response?.insightOptions;
        setInsightOptions(options);
      }
    },
    // This function only needs to be created once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { hasFeatureAccessOrganization } = useFeatureAccessInfoContext();
  const hasInitiativeAccess = hasFeatureAccessOrganization('scorecard-roadmap', orgId!);

  const { reloadRows, providerValue, displayFeatures } = useConsole(
    reloadAssetRows,
    getFilterFrameFromUrl(),
    ['Forecast'],
    onConsoleResponse,
  );

  const consoleContextReset = React.useCallback(() => {
    updateUrlParameter('Forecast', undefined);
    providerValue.setFilterFrame(getFilterFrameFromUrl);
  }, [providerValue]);

  const { downloadAssetSpreadsheet } = useDownloadAssetSpreadsheet(frontendScope, assetType);

  const [allowSelectingAssetsForInitiative, setAllowSelectingAssetsForInitiative] = React.useState(false);

  const displayFeaturesHas = (feature: DisplayFeaturesEnum): boolean => displayFeatures?.has(feature) ?? false;

  React.useEffect(() => {
    const canUseInitiatives = hasOrganizationScope && hasInitiativeAccess && displayFeatures?.has(DisplayFeaturesEnum.Initiative);

    const canSelectAssets = canUseInitiatives !== undefined ? canUseInitiatives : false;
    setAllowSelectingAssetsForInitiative(canSelectAssets);
  }, [displayFeatures, setAllowSelectingAssetsForInitiative, hasInitiativeAccess, hasOrganizationScope, orgId]);

  const upgradeBanner = upgradeUrl ? (
    <AssetConsoleUpgradeBanner
      upgradeUrl={upgradeUrl}
      scope={frontendScope}
    />
  ) : undefined;

  const { consoleState, columns: selectedColumns } = useContext(ConsoleContext);

  const extraToolbarButtons: { control: ToolbarControl; order: number }[] = [];

  if (typeof clientSettingsUrl !== 'undefined') {
    extraToolbarButtons.push({
      control: {
        expanded: (
          <SettingsLink
            settingsUrl={clientSettingsUrl}
            tooltipContent={`Customize settings ${clientName ? `for ${clientName}` : ''}`}
            tooltipSetting={'auto'}
          />
        ),
      },
      order: 0,
    });
  }

  if (typeof consoleShareProps !== 'undefined') {
    extraToolbarButtons.push({
      control: {
        expanded: (
          <ConsoleShareButton
            consoleShareProps={consoleShareProps}
            consoleState={consoleState}
            selectedColumns={selectedColumns}
          />
        ),
      },
      order: 1,
    });
  }

  if (typeof hasOrganizationScope !== 'undefined' && typeof lifecycleReportUrl !== 'undefined') {
    extraToolbarButtons.push({
      control: {
        expanded: (
          <LifecycleReportButton
            frontendScope={frontendScope}
            organizationId={orgId!}
            lifecycleReportDownloadLocation={'hardware'}
            canDownload={canDownload}
            hasHardwareLifecycleReportAccess={displayFeaturesHas(DisplayFeaturesEnum.HardwareLifecycleReportDownload)}
          />
        ),
      },
      order: 4,
    });
  }

  return (
    <ConsoleContext.Provider value={providerValue}>
      <AsyncConsole
        onFetchRows={reloadRows}
        onFetchIds={reloadIds}
        topFilterLine={
          <div
            css={css`
              width: 100%;
            `}
          >
            <AssetInsightDropdown
              options={insightOptions}
              onInsightSelected={selected => setSelectedInsight(selected)}
            />
          </div>
        }
        tableHeaderSection={displayFeaturesHas(DisplayFeaturesEnum.Forecast) ? <ForecastFilter /> : undefined}
        hasCheckboxes={allowSelectingAssetsForInitiative}
        noResultsText={`No ${assetTypeName}${typeof clientName === 'undefined' ? '' : ` at ${clientName}`} match these filters.`}
        banner={upgradeBanner}
        reset={consoleContextReset}
        extraToolbarButtons={extraToolbarButtons}
        download={(consoleState, selectedColumns, context, spreadsheetType) =>
          downloadAssetSpreadsheet(consoleState, selectedColumns, context, spreadsheetType)
        }
        canDownload={canDownload}
        legacyDownloadUrl={legacyDownloadUrl}
        displayFeatures={displayFeatures}
      />
      {allowSelectingAssetsForInitiative && (
        <LayoutFooter
          leftControls={[
            {
              expanded: (
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background: ${colorToCode('primary-050')};
                    ${providerValue.selectedConsoleItems.selectedItems.size > 0 &&
                    `padding-inline-start: 1.875rem;
                    padding-inline-end: 0.313rem;
                    padding-block: 0.313rem;
                    border-radius: ${borderRadiusToCode('radius400')};`}
                  `}
                >
                  <SelectedDisplayCount />
                  {displayFeaturesHas(DisplayFeaturesEnum.Initiative) && (
                    <RegionalSettingsInfoProvider
                      frontendScope={{
                        type: ScopeEnum.Organization,
                        organizationId: orgId!,
                      }}
                    >
                      <AddToInitiativeButton
                        organizationId={orgId!}
                        insightInformation={
                          (selectedInsight !== undefined
                            ? {
                                id: selectedInsight.insightId,
                                name: selectedInsight.title,
                                assetCount: providerValue.selectedConsoleItems.selectedItems.size,
                              }
                            : undefined) as InsightInformation
                        }
                        createdFrom={StrategyDataCollectionSources.initiativeCreationSource.universalConsole}
                        disabled={providerValue.selectedConsoleItems.selectedItems.size <= 0}
                        assetsAddedFrom={StrategyDataCollectionSources.initiativeAssetsAddedSource.universalConsole}
                        selectedIds={providerValue.selectedConsoleItems.selectedItems}
                      />
                    </RegionalSettingsInfoProvider>
                  )}
                </div>
              ),
            },
          ]}
        />
      )}
    </ConsoleContext.Provider>
  );
};

export default AssetConsole;
