import React from 'react';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import WarrantyCartPageHeader from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-page-header/WarrantyCartPageHeader';
import WarrantyCartDeviceTypeSwitch from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-device-type-switch/WarrantyCartDeviceTypeSwitch';
import WarrantyCartEmptyState from '~/wm/packages/warranty/packages/warranty-cart/WarrantyCartEmptyState';
import WarrantyCartSummary from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-summary/WarrantyCartSummary';
import WarrantyCartOpportunity from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-opportunity/WarrantyCartOpportunity';
import WarrantyCartTotalCost from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-total-cost/WarrantyCartTotalCost';
import WarrantyCartSelectText from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-select-text/WarrantyCartSelectText';
import WarrantyCartFiltersProvider from '~/wm/packages/warranty/packages/warranty-cart/context/WarrantyCartFiltersProvider';
import WarrantyCartFilters from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-filters/WarrantyCartFilters';
import WarrantyCartDevicesTable from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-devices-table/WarrantyCartDevicesTable';
import WarrantyCartCurrencyFooter from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-currency-footer/WarrantyCartCurrencyFooter';
import WarrantyCartContinuousPolicyFooter from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-continuous-policy-footer/WarrantyCartContinuousPolicyFooter';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';
import useUserContext from '~/wm/hooks/useUserContext';
import { Enum as UserPermissionEnum } from '@SsoClient/BeastClient/Beast/Identity/Model/UserPermission/UserPermissionFactoryNested.gen';
import WarrantyCartPermissionBanner from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-permission-banner/WarrantyCartPermissionBanner';

const WarrantyCart: React.FunctionComponent = () => {
  const { displayEmptyStateImage } = useWarrantyCartContext();
  const { 'sp-account-phase-1': spAccountPhaseOneEnabled } = useFeatureFlagProvider();
  const { getUserPermission } = useUserContext();

  const canCheckout =
    (spAccountPhaseOneEnabled ? getUserPermission(UserPermissionEnum.PlaceOrders) : getUserPermission(UserPermissionEnum.ManageBilling)) ||
    getUserPermission(UserPermissionEnum.AccountAdmin);

  return (
    <>
      {!canCheckout && <WarrantyCartPermissionBanner />}
      <WarrantyCartPageHeader />
      <WarrantyCartDeviceTypeSwitch />
      {displayEmptyStateImage ? (
        <WarrantyCartEmptyState />
      ) : (
        <>
          <WarrantyCartSummary />
          <WarrantyCartOpportunity />
          <WarrantyCartTotalCost
            useAdditionalItems={true}
            canCheckout={canCheckout}
          />
          <WarrantyCartSelectText />
          <WarrantyCartFiltersProvider>
            <WarrantyCartFilters />
            <WarrantyCartDevicesTable />
          </WarrantyCartFiltersProvider>
          <WarrantyCartTotalCost
            useAdditionalItems={false}
            canCheckout={canCheckout}
          />
          <WarrantyCartCurrencyFooter />
          <WarrantyCartContinuousPolicyFooter />
        </>
      )}
    </>
  );
};

export default WarrantyCart;
